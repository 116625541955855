import { useMemo } from 'react';
import { SearchHistoryProps } from 'services/api/typings';
import { useSearchHistory } from 'services/api/useTraceability';

const useTraceFilters = () => {
  const { data: searchHistoryAPI, isLoading: isSearchHistoryLoading } = useSearchHistory();

  const searchHistory: Array<SearchHistoryProps> = useMemo<SearchHistoryProps[]>(
    () =>
      searchHistoryAPI?.searchHistories?.map<SearchHistoryProps>((item) => ({
        gtin: item.gtin,
        lot: item.lotId,
        searchDate: item.createdDate?.toISOString(),
        userName: item.userName,
      })) || [],
    [searchHistoryAPI],
  );
  return { searchHistory, isSearchHistoryLoading };
};

export default useTraceFilters;
