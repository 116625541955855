import { ProFormColumnsType } from '@ant-design/pro-form';
import TraceabilityTable, { TraceabilityColumns } from './TraceabilityTable';

export type DataItem = {
  traceabilityTable: Array<TraceabilityColumns>;
};

export const TraceabilityTableFields = (): ProFormColumnsType<DataItem>[] => [
  {
    dataIndex: 'traceabilityTable',
    initialValue: [],
    renderFormItem: (_schema, _config, form) => <TraceabilityTable form={form} />,
    colProps: {
      xs: 24,
      md: 24,
    },
  },
];

export default TraceabilityTableFields;
