import { Property } from 'csstype';
import { CSSProperties } from 'react';

// Secondary Action Button styles
export const SecondaryBtnStyle: CSSProperties = {
  color: '#0a5f7a',
  borderColor: '#0a5f7a',
  background: 'transparent',
  textShadow: '0px 0px',
  boxShadow: '0px 0px',
};

// Primary Action Button styles
export const PrimaryBtnStyle: CSSProperties = {
  color: '#0a5f7a',
  borderColor: '#bcd530',
  background: '#bcd530',
  textShadow: '0px 0px',
  boxShadow: '0px 0px',
};

// Delete Action Button styles
export const DeleteBtnStyle: CSSProperties = {
  color: '#FFFFFF',
  borderColor: '#FF4D4F',
  background: '#FF4D4F',
  textShadow: '0px 0px',
  boxShadow: '0px 0px',
};

// modals Submit Button style
export const ModalSubmitBtnStyle: CSSProperties = {
  color: '#0a5f7a',
  background: '#bcd530',
  border: '1px solid #bcd530',
};

// Tag capsules style
export const ColoredTagStyle: CSSProperties = {
  borderRadius: '15px',
  fontWeight: '500',
  color: '#0A5F7A',
  border: '1.3px solid #0A5F7A',
  backgroundColor: '#E7F0F2',
};

// Grey tag capsules
export const GreyTagStyle: CSSProperties = {
  borderRadius: '15px',
  fontWeight: '500',
  color: '#9D9D9D',
  border: '1.3px solid #9D9D9D',
};

export const ActionsOverlayStyles: CSSProperties = { borderRadius: '2px', zIndex: '1001' };

export const LayoutContentStyle: CSSProperties = {
  backgroundColor: '#F4F4F4',
};

export const IfTraceContentStyle: CSSProperties = {
  backgroundColor: '#fff',
};

export const TraceModalsBtnStyle: CSSProperties = { zIndex: '1500' };

export const UniversalSearchDropdownStyles: CSSProperties = {
  position: 'fixed' as Property.Position,
};

export const ActiveBadge: CSSProperties = {
  color: '#0a5f7a',
  backgroundColor: '#E6EFF2',
};

export const InActiveBadge: CSSProperties = {
  color: 'black',
  backgroundColor: '#F4f4f4',
};

export const RolesExpand: CSSProperties = {
  padding: '8px',
  paddingLeft: '232px',
  borderBottom: '0.5px solid #ededed',
};

export const PermissionsExpand: CSSProperties = {
  paddingLeft: '30px',
};

export const mgt8: CSSProperties = {
  marginTop: '8px',
};

export const mgt16: CSSProperties = {
  marginTop: '16px',
};

export const FooterStyle: CSSProperties = {
  textAlign: 'center' as Property.TextAlign,
  padding: '15px',
  backgroundColor: '#F4F4F4',
  color: '#8C8C8C',
};

export const TraceFooterStyle: CSSProperties = {
  textAlign: 'center' as Property.TextAlign,
  padding: '15px',
  backgroundColor: '#fff',
  color: '#8C8C8C',
};
