import { ProColumns } from '@ant-design/pro-table';
import { Button } from 'antd';
import GTable from 'components/GTable';
import useModalVisibility from 'hooks/useModalVisibility';
import { UseModalVisibilityReturnType } from 'hooks/useModalVisibility/typings';
import { FC, useCallback, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { EventListAPIFields } from 'services/api';
import { EventDTO, EventProductDTO } from 'services/api/client/src';
import styles from '../index.module.less';
import ListModal from './Modal';
import { TraceListProps } from './typings';

type ActionItemsProps = {
  t: TFunction<'pages', 'trace.trace_list'>;
  listModal: UseModalVisibilityReturnType;
  setSelectedLotData: any;
};

const columns = ({
  t,
  listModal,
  setSelectedLotData,
}: ActionItemsProps): Array<ProColumns<EventListAPIFields>> => [
  {
    title: t('product_name'),
    dataIndex: 'productName',
    ellipsis: true,
  },
  {
    title: t('product_identifier'),
    dataIndex: 'productIdentifier',
    ellipsis: true,
  },
  {
    title: t('primary_id'),
    dataIndex: 'primaryId',
    ellipsis: true,
    render: (text: any, record: EventListAPIFields) => (
      <Button
        type="link"
        onClick={() => {
          setSelectedLotData(record);
          listModal.show();
        }}
        style={{ padding: '0px', margin: '0px' }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: t('quantity'),
    dataIndex: 'quantity',
    ellipsis: true,
  },
  {
    title: t('event'),
    dataIndex: 'eventType',
    ellipsis: true,
  },
  {
    title: t('company'),
    dataIndex: 'company',
    ellipsis: true,
  },
  {
    title: t('date'),
    dataIndex: 'eventDate',
    ellipsis: true,
  },
];

const TraceTable: FC<TraceListProps> = ({ allEventsList = [], isAllEventsListLoading }) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_list',
  });
  const listModal = useModalVisibility(false);

  const [selectedLotData, setSelectedLotData] = useState<EventProductDTO | undefined>();
  const [selectedEventData, setSelectedEventData] = useState<EventDTO | undefined>();

  const setSelection = useCallback(
    (record: EventListAPIFields) => {
      const event = allEventsList.find((evt) =>
        evt.products?.some((prod) => prod.lotId === record.primaryId),
      );
      const lot = event?.products?.filter((prod) => prod.lotId === record.primaryId)[0];

      setSelectedLotData(lot);
      setSelectedEventData(event);
    },
    [allEventsList],
  );
  return (
    <>
      {listModal.visible && (
        <ListModal
          selectedLotData={selectedLotData}
          selectedEventData={selectedEventData}
          modal={listModal}
        />
      )}
      <div className={styles.table}>
        <GTable<EventListAPIFields>
          columns={columns({ t, listModal, setSelectedLotData: setSelection })}
          value={allEventsList
            .flatMap(
              (evt) =>
                evt.products?.map((prod) => ({
                  productName: prod.shortDescription,
                  productIdentifier: prod.gtin ?? prod.epc,
                  primaryId: prod.lotId,
                  quantity: prod.quantity,
                  eventType: evt.eventType,
                  company: evt.productOwner?.name,
                  location: evt.location?.name,
                  eventDate: evt.eventTime?.toISOString().replace('T', ' ').substring(0, 19),
                })) ?? [],
            )
            .sort(
              (a, b) =>
                new Date(b.eventDate ?? '0001-01-01').getTime() -
                new Date(a.eventDate ?? '0001-01-01').getTime(),
            )}
          enableRecordCreator
          options={{
            reload: false,
            setting: true,
          }}
          columnsState={{
            defaultValue: {
              sku: {
                show: false,
              },
            },
          }}
          loading={isAllEventsListLoading}
          recordCreatorProps={false}
          pagination={{ defaultPageSize: 20, showSizeChanger: true }}
          scroll={{ y: 620, x: 1800 }}
        />
      </div>
    </>
  );
};

export default TraceTable;
