import * as coreClient from "@azure/core-client";
import * as coreRestPipeline from "@azure/core-rest-pipeline";

export interface AccessRequest {
  endorserId?: string;
  auditReports?: boolean;
}

export interface AccessResponse {
  endorserId?: string;
  auditReports?: boolean;
}

export interface GrantAccessRequest {
  requestedEndorserId?: string;
  accessRequests?: AccessRequest[];
}

export interface GrantAccessResponse {
  endorserId?: string;
  accessResponses?: AccessResponse[];
}

export interface ListGrantAccessRequest {
  requests?: GrantAccessRequest[];
}

export interface ListGrantAccessResponse {
  results?: GrantAccessResponse[];
}

export interface RevokeAccessRequest {
  endorserId?: string;
  grantedEndorserId?: string;
}

export interface RevokeAccessResponse {
  endorserId?: string;
  accessResponses?: AccessResponse[];
}

export interface UpdateAccessResponse {
  endorserId?: string;
  accepted?: boolean;
}

export interface ListAccessResponse {
  results?: AccessItemDTO[];
}

export interface AccessItemDTO {
  endorserId?: string;
  endorserName?: string;
  auditReports?: boolean;
  bapNumber?: string;
  accepted?: boolean;
}

export interface ListMyAccessResponse {
  results?: AccessItemDTO[];
}

export interface ListAccountsResponse {
  totalPages?: number;
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  results?: EndorserDTO[];
}

export interface EndorserDTO {
  accountID?: string;
  bapNumber?: string;
  facility?: string;
  facilityType?: string;
  plantType?: string;
  country?: string;
  expiration?: Date;
  partnerType?: string;
  accessCount?: number;
  enhancedSocialAccountabilityDone?: boolean;
  announceType?: string;
  access?: EndorserAccess[];
  species?: SpeciesDTO[];
}

export interface EndorserAccess {
  id?: string;
  accountID?: string;
  accessItems?: AccessItem[];
  apiKey?: string;
  traceabilityProvider?: EndorserAccessTraceabilityProvider;
  bapNumber?: string;
  name?: string;
}

export interface AccessItem {
  endorserId?: string;
  bapNumber?: string;
  endorserName?: string;
  auditReports?: boolean;
  accepted?: boolean;
}

export interface SpeciesDTO {
  category?: string;
  stars?: number;
}

export interface ListAuditReportsResponse {
  totalPages?: number;
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  results?: AuditReportItem[];
}

export interface AuditReportItem {
  reportId?: string;
  bapNumber?: string;
  facilityName?: string;
  status?: string;
  auditType?: string;
  submittedDate?: Date;
  auditEndDate?: Date;
}

export interface GetAuditReportByIdResponse {
  auditReport?: AuditReportDTO;
}

export interface AuditReportDTO {
  bapNumber?: string;
  reportId?: string;
  groupManager?: string;
  distanceFromFarthestFacility?: string;
  reportType?: string;
  primaryProductionMethod?: string;
  cageTypes?: string;
  issueName?: string;
  auditKpi?: AuditKpidto;
  speciesDetails?: SpeciesDetailDTO[];
  memberSpeciesDetails?: MemberSpeciesDetailDTO[];
  speciesTotals?: SpeciesTotals;
  assessmentTotals?: AssessmentTotals;
  groupAssessmentTotals?: AssessmentTotals[];
  onSiteAssessmentTotals?: AssessmentTotals[];
  facilityWorkforce?: AuditFacilityWorkforceDTO;
  auditPersonnel?: AuditPersonnelDTO[];
  assessmentAnswers?: AssessmentAnswerDTO[];
}

export interface AuditKpidto {
  issueName?: string;
  cbName?: string;
  orgName?: string;
  auditorName?: string;
  address?: string;
  auditStartDate?: Date;
  auditEndDate?: Date;
  latitude?: number;
  longitude?: number;
  announceType?: string;
  remoteDone?: boolean;
  catchDesignation?: string;
  esaAuditDone?: boolean;
  governmentLicenseNumber?: string;
  auditContactName?: string;
  website?: string;
  auditContactEmail?: string;
  email?: string;
  auditContactPhone?: string;
  plantType?: string;
  accountType?: string;
  auditType?: string;
  auditDurationHour?: string;
  assigned2NdAuditor?: string;
  shadower?: string;
  witness?: string;
  socialAccountabilityAuditor?: string;
  observer?: string;
  apscaFirmNumber?: string;
  apscaAuditorNumber?: string;
  auditNotes?: string;
  siteVisitNotes?: string;
  lastAuditDate?: Date;
  submittedDate?: Date;
  certifiedSinceDate?: string;
  survivalRate?: number;
  fcr?: number;
  companyProfile?: string;
  totalEmployees?: number;
  remoteDesktopReviewTools?: string;
  remoteDesktopReviewEffectiveness?: string;
  remoteDocumentVerificationTools?: string;
  remoteDocumentVerificationEffectiveness?: string;
  remoteInterviewTools?: string;
  remoteInterviewEffectiveness?: string;
  remoteMeetingTools?: string;
  remoteMeetingEffectiveness?: string;
  remoteSiteInspectionTools?: string;
  remoteSiteInspectiongEffectiveness?: string;
}

export interface SpeciesDetailDTO {
  scientificName?: string;
  speciesCategory?: string;
  sourceType?: string;
  auditType?: string;
  productForms?: string;
  productionDetails?: string;
  totalProduction?: number;
  latitude?: number;
  longitude?: number;
  bapNumber?: string;
  accountName?: string;
  numberOfAnimals?: number;
  memberOnSiteHousing?: boolean;
}

export interface MemberSpeciesDetailDTO {
  speciesCategory?: string;
  sourceType?: string;
  auditType?: string;
  productForms?: string;
  productionDetails?: string;
  latitude?: number;
  longitude?: number;
  bapNumber?: string;
  accountName?: string;
  numberOfAnimals?: number;
  memberOnSiteHousing?: boolean;
  speciesProduction?: SpeciesProduction[];
}

export interface SpeciesProduction {
  scientificName?: string;
  totalProduction?: number;
}

export interface SpeciesTotals {
  totalProduction?: number;
  farmed?: number;
  wildCaught?: number;
}

export interface AssessmentTotals {
  critical?: number;
  major?: number;
  minor?: number;
  yes?: number;
  notAvailable?: number;
  bapNumber?: string;
  phase?: string;
}

export interface AuditFacilityWorkforceDTO {
  totalEmployeesInFacilityHousing?: number;
  employmentAgenciesUsed?: boolean;
  onsiteHousing?: boolean;
  onsiteHousingSubcontracted?: boolean;
  managementNationality1?: string;
  managementNationality2?: string;
  managementNationality3?: string;
  workersNationality1?: string;
  workersNationalityPercentage1?: number;
  workersNationality2?: string;
  workersNationalityPercentage2?: number;
  workersNationality3?: string;
  workersNationalityPercentage3?: number;
  workersNationality4?: string;
  workersNationalityPercentage4?: number;
  workersNationality5?: string;
  workersNationalityPercentage5?: number;
  productionSupervisorMale?: number;
  productionSupervisorFemale?: number;
  localMaleWorkersTemporary?: number;
  localMaleWorkersPermanent?: number;
  localMaleWorkersAgency?: number;
  localtFemaleWorkersTemporary?: number;
  localFemaleWorkersPermanent?: number;
  localFemaleWorkersAgency?: number;
  migrantMaleWorkersTemporary?: number;
  migrantMaleWorkersPermanent?: number;
  migrantMaleWorkersAgency?: number;
  migrantFemaleWorkersTemporary?: number;
  migrantFemaleWorkersPermanent?: number;
  migrantFemaleWorkersAgency?: number;
  isSecondThirdShift?: string;
  otherShiftActivities?: string;
  isOnsiteShiftProviders?: string;
  serviceProviderDetails?: string;
}

export interface AuditPersonnelDTO {
  name?: string;
  jobTitle?: string;
  openingMeeting?: boolean;
  siteInspection?: boolean;
  procedureReview?: boolean;
  closingMeeting?: boolean;
}

export interface AssessmentAnswerDTO {
  referenceNumber?: string;
  pillar?: string;
  answer?: string;
  clause?: string;
  explanation?: string;
  phase?: string;
  bapNumber?: string;
}

export interface GetAssessmentAnswersResponse {
  issueName?: string;
  assessmentType?: string;
  results?: AssessmentAnswerDTO[];
}

export interface ListFacilityLocationsResponse {
  totalPages?: number;
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  results?: EndorserLocationDTO[];
}

export interface EndorserLocationDTO {
  accountID?: string;
  bapNumber?: string;
  country?: string;
  addressZip?: string;
  addressStreet2?: string;
  addressStreet1?: string;
  addressState?: string;
  addressCity?: string;
  facility?: string;
  latitude?: number;
  longitude?: number;
}

export interface ListEndorsersResponse {
  totalPages?: number;
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  results?: EndorserDTO[];
}

export interface GetEndorserByIdResponse {
  endorser?: EndorserDTO;
  traceabilityProviders?: TraceabilityProviderDTO[];
}

export interface TraceabilityProviderDTO {
  accountId?: string;
  traceabilityProviderId?: string;
  digitalLink?: string;
  connectionName?: string;
  traceabilitySystem?: TraceabilityProviderDTOTraceabilitySystem;
  createdDate?: string;
  gtins?: string[];
}

export interface ListNetworkResponse {
  totalPages?: number;
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  results?: EndorserDTO[];
}

export interface SignJwtResponse {
  signedToken?: string;
}

export interface ListCountriesResponse {
  countries?: string[];
}

export interface ListSpeciesResponse {
  species?: string[];
}

export interface ListPermissionsResponse {
  permissions?: PermissionItem[];
}

export interface PermissionItem {
  permission?: PermissionItemPermission;
  name?: string;
  description?: string;
}

export interface ListNotificationsResponse {
  totalPages?: number;
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  results?: NotificationDTO[];
  unreadCount?: number;
}

export interface NotificationDTO {
  id?: string;
  title?: string;
  description?: string;
  bapNumber?: string;
  auditId?: string;
  type?: NotificationDTOType;
  createdDate?: Date;
  recentActivity?: boolean;
  read?: boolean;
  accountId?: string;
  pictureUrl?: string;
  inviteId?: string;
}

export interface ClearNotificationsRequest {
  notificationIds?: string[];
  recentActivity?: boolean;
}

export interface ClearNotificationsResponse {
  notifications?: NotificationDTO[];
}

export interface NotifyHelpDeskRequest {
  text?: string;
  subject?: string;
}

export interface ListProductsResponse {
  products?: Product[];
}

export interface Product {
  accountId?: string;
  urn?: string;
  shortDescription?: string;
  conditionCode?: string;
  speciesCode?: string;
  speciesName?: string;
}

export interface ListReportsResponse {
  reports?: ReportItemDTO[];
}

export interface ReportItemDTO {
  tabName?: string;
  url?: string;
}

export interface CreateRoleRequest {
  roleName?: string;
  permission?: CreateRoleRequestPermissionItem[];
}

export interface CreateUpdateRoleResponse {
  id?: string;
}

export interface UpdateRoleRequest {
  roleName?: string;
  permission?: UpdateRoleRequestPermissionItem[];
  roleId?: string;
}

export interface ListRolesResponse {
  roles?: RoleItem[];
}

export interface RoleItem {
  roleId?: string;
  roleName?: string;
  permission?: RoleItemPermissionItem[];
}

export interface GetRoleByIdResponse {
  roleId?: string;
  roleName?: string;
  permissions?: GetRoleByIdResponsePermissionsItem[];
}

export interface DeleteRoleResponse {
  roleId?: string;
}

export interface AssignRolesRequest {
  roleId?: string;
  userId?: string;
  endorserRole?: AssignRolesRequestEndorserRole;
}

export interface AssignRolesResponse {
  roleId?: string;
  userId?: string;
}

export interface SearchResponse {
  results?: SearchResult[];
}

export interface SearchResult {
  endorserId?: string;
  endorserName?: string;
  facilityType?: string;
  bapNumber?: string;
  country?: string;
  species?: string;
  resultType?: SearchResultType;
  productId?: string;
  productDescription?: string;
  lotId?: string;
  lotURN?: string;
  partnerType?: string;
}

export interface ListSearchHistoryByUserIdResponse {
  searchHistories?: SearchHistoryDTO[];
}

export interface SearchHistoryDTO {
  userName?: string;
  lotId?: string;
  createdDate?: Date;
  gtin?: string;
}

export interface ListEventsResponse {
  events?: EventDTO[];
}

export interface EventDTO {
  urn?: string;
  eventType?: string;
  productOwner?: TradingPartyDTO;
  informationProvider?: TradingPartyDTO;
  bizStep?: string;
  action?: string;
  eventTime?: Date;
  containerId?: string;
  products?: EventProductDTO[];
  location?: EventLocationDTO;
  sourceList?: TradingPartyDTO[];
  destinationList?: TradingPartyDTO[];
  readPoint?: string;
}

export interface TradingPartyDTO {
  urn?: string;
  name?: string;
}

export interface EventProductDTO {
  epc?: string;
  gtin?: string;
  shortDescription?: string;
  conditionCode?: string;
  speciesCode?: string;
  speciesName?: string;
  quantity?: number;
  uom?: string;
  lotId?: string;
  direction?: string;
  type?: string;
}

export interface EventLocationDTO {
  urn?: string;
  address?: string;
  name?: string;
  latitude?: number;
  longitude?: number;
}

export interface MapResponse {
  map?: MapDiagram;
  productName?: string;
  lotSerial?: string;
}

export interface MapDiagram {
  nodes?: LocationNode[];
  edges?: MapDiagramEdge[];
  productName?: string;
}

export interface LocationNode {
  urn?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  events?: EventDTO[];
}

export interface MapDiagramEdge {
  source?: string;
  target?: string;
}

export interface DiagramResponse {
  diagram?: EventDiagram;
}

export interface EventDiagram {
  nodes?: EventDTO[];
  edges?: MapDiagramEdge[];
}

export interface ListTraceabilityProviderResponse {
  traceabilityProviders?: TraceabilityProviderDTO[];
}

export interface CreateTraceabilityRequest {
  connectionName?: string;
  digitalLink?: string;
  apiKey?: string;
  gtins?: string[];
  traceabilityProvider?: CreateTraceabilityRequestTraceabilityProvider;
}

export interface CreateTraceabilityResponse {
  traceabilityProviderDTO?: TraceabilityProviderDTO;
}

export interface UpdateTraceabilityRequest {
  traceabilityId?: string;
  connectionName?: string;
  digitalLink?: string;
  apiKey?: string;
  gtins?: string[];
}

export interface UpdateTraceabilityResponse {
  traceabilityId?: string;
}

export interface ListEndorsersTraceabilityResponse {
  endorsersAccess?: EndorserAccessDTO[];
}

export interface EndorserAccessDTO {
  accountID?: string;
  traceabilityProvider?: EndorserAccessDTOTraceabilityProvider;
  bapNumber?: string;
  name?: string;
}

export interface DeleteTraceabilityResponse {
  accountId?: string;
  traceabilityId?: string;
}

export interface UpdateUserRequest {
  stateProvince?: string;
  city?: string;
  country?: string;
  phone?: string;
  phoneCode?: string;
  name?: string;
  password?: string;
  displayName?: string;
  notificationsOn?: boolean;
  apiKey?: string;
  traceabilityProvider?: UpdateUserRequestTraceabilityProvider;
}

export interface UpdateUserResponse {
  user?: UserDTO;
}

export interface UserDTO {
  userId?: string;
  email?: string;
  name?: string;
  phone?: string;
  phoneCode?: string;
  profilePictureUrl?: string;
  role?: RoleItem;
  endorserAccountId?: string;
  firstLogin?: boolean;
  userType?: UserDTOUserType;
}

export interface MeResponse {
  user?: UserDTO;
  endorser?: EndorserDTO;
  notificationsOn?: boolean;
}

export interface ListUsersResponse {
  users?: UserDTO[];
}

export interface GetUserByIdResponse {
  user?: UserDTO;
}

export interface DeleteUserResponse {
  userId?: string;
}

export interface UploadProfilePictureResponse {
  id?: string;
  url?: string;
}

export interface InviteUserResponse {
  emails?: string[];
}

export interface PathsGp74B2UsersMePicturePostRequestbodyContentMultipartFormDataSchema {
  picture?: coreRestPipeline.RequestBodyType;
}

export interface Paths7Ocvg4UsersInvitePostRequestbodyContentMultipartFormDataSchema {
  emails?: string[];
  message?: string;
  roleId?: string;
  accountId?: string;
  endorserRole?: PostContentSchemaEndorserRole;
}

/** Defines headers for WebAPIClient_exportReport operation. */
export interface WebAPIClientExportReportHeaders {
  xFileName?: string;
}

/** Defines headers for WebAPIClient_getFirstPageById operation. */
export interface WebAPIClientGetFirstPageByIdHeaders {
  xFileName?: string;
}

/** Known values of {@link Enum0} that the service accepts. */
export enum KnownEnum0 {
  /** Facility */
  Facility = "Facility",
  /** FacilityType */
  FacilityType = "FacilityType",
  /** Country */
  Country = "Country",
  /** BAPNumber */
  BAPNumber = "BAPNumber",
  /** Expiration */
  Expiration = "Expiration",
}

/**
 * Defines values for Enum0. \
 * {@link KnownEnum0} can be used interchangeably with Enum0,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Facility** \
 * **FacilityType** \
 * **Country** \
 * **BAPNumber** \
 * **Expiration**
 */
export type Enum0 = string;

/** Known values of {@link Enum1} that the service accepts. */
export enum KnownEnum1 {
  /** Asc */
  Asc = "Asc",
  /** Desc */
  Desc = "Desc",
}

/**
 * Defines values for Enum1. \
 * {@link KnownEnum1} can be used interchangeably with Enum1,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Asc** \
 * **Desc**
 */
export type Enum1 = string;

/** Known values of {@link EndorserAccessTraceabilityProvider} that the service accepts. */
export enum KnownEndorserAccessTraceabilityProvider {
  /** Wholechain */
  Wholechain = "WHOLECHAIN",
  /** None */
  None = "NONE",
}

/**
 * Defines values for EndorserAccessTraceabilityProvider. \
 * {@link KnownEndorserAccessTraceabilityProvider} can be used interchangeably with EndorserAccessTraceabilityProvider,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **WHOLECHAIN** \
 * **NONE**
 */
export type EndorserAccessTraceabilityProvider = string;

/** Known values of {@link Enum3} that the service accepts. */
export enum KnownEnum3 {
  /** ReportId */
  ReportId = "ReportId",
  /** BapNumber */
  BapNumber = "BapNumber",
  /** OrgName */
  OrgName = "OrgName",
  /** SubmittedDate */
  SubmittedDate = "SubmittedDate",
  /** AuditEndDate */
  AuditEndDate = "AuditEndDate",
}

/**
 * Defines values for Enum3. \
 * {@link KnownEnum3} can be used interchangeably with Enum3,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **ReportId** \
 * **BapNumber** \
 * **OrgName** \
 * **SubmittedDate** \
 * **AuditEndDate**
 */
export type Enum3 = string;

/** Known values of {@link Enum4} that the service accepts. */
export enum KnownEnum4 {
  /** Asc */
  Asc = "Asc",
  /** Desc */
  Desc = "Desc",
}

/**
 * Defines values for Enum4. \
 * {@link KnownEnum4} can be used interchangeably with Enum4,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Asc** \
 * **Desc**
 */
export type Enum4 = string;

/** Known values of {@link Enum5} that the service accepts. */
export enum KnownEnum5 {
  /** ReferenceNumber */
  ReferenceNumber = "ReferenceNumber",
  /** Pillar */
  Pillar = "Pillar",
  /** Answer */
  Answer = "Answer",
  /** CauseExplanation */
  CauseExplanation = "CauseExplanation",
}

/**
 * Defines values for Enum5. \
 * {@link KnownEnum5} can be used interchangeably with Enum5,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **ReferenceNumber** \
 * **Pillar** \
 * **Answer** \
 * **CauseExplanation**
 */
export type Enum5 = string;

/** Known values of {@link Enum6} that the service accepts. */
export enum KnownEnum6 {
  /** Asc */
  Asc = "Asc",
  /** Desc */
  Desc = "Desc",
}

/**
 * Defines values for Enum6. \
 * {@link KnownEnum6} can be used interchangeably with Enum6,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Asc** \
 * **Desc**
 */
export type Enum6 = string;

/** Known values of {@link Enum7} that the service accepts. */
export enum KnownEnum7 {
  /** ReferenceNumber */
  ReferenceNumber = "ReferenceNumber",
  /** Pillar */
  Pillar = "Pillar",
  /** Answer */
  Answer = "Answer",
  /** CauseExplanation */
  CauseExplanation = "CauseExplanation",
}

/**
 * Defines values for Enum7. \
 * {@link KnownEnum7} can be used interchangeably with Enum7,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **ReferenceNumber** \
 * **Pillar** \
 * **Answer** \
 * **CauseExplanation**
 */
export type Enum7 = string;

/** Known values of {@link Enum8} that the service accepts. */
export enum KnownEnum8 {
  /** Asc */
  Asc = "Asc",
  /** Desc */
  Desc = "Desc",
}

/**
 * Defines values for Enum8. \
 * {@link KnownEnum8} can be used interchangeably with Enum8,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Asc** \
 * **Desc**
 */
export type Enum8 = string;

/** Known values of {@link Enum9} that the service accepts. */
export enum KnownEnum9 {
  /** Facility */
  Facility = "Facility",
  /** FacilityType */
  FacilityType = "FacilityType",
  /** Country */
  Country = "Country",
  /** BAPNumber */
  BAPNumber = "BAPNumber",
  /** Expiration */
  Expiration = "Expiration",
}

/**
 * Defines values for Enum9. \
 * {@link KnownEnum9} can be used interchangeably with Enum9,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Facility** \
 * **FacilityType** \
 * **Country** \
 * **BAPNumber** \
 * **Expiration**
 */
export type Enum9 = string;

/** Known values of {@link Enum10} that the service accepts. */
export enum KnownEnum10 {
  /** Asc */
  Asc = "Asc",
  /** Desc */
  Desc = "Desc",
}

/**
 * Defines values for Enum10. \
 * {@link KnownEnum10} can be used interchangeably with Enum10,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Asc** \
 * **Desc**
 */
export type Enum10 = string;

/** Known values of {@link TraceabilityProviderDTOTraceabilitySystem} that the service accepts. */
export enum KnownTraceabilityProviderDTOTraceabilitySystem {
  /** Wholechain */
  Wholechain = "WHOLECHAIN",
  /** None */
  None = "NONE",
}

/**
 * Defines values for TraceabilityProviderDTOTraceabilitySystem. \
 * {@link KnownTraceabilityProviderDTOTraceabilitySystem} can be used interchangeably with TraceabilityProviderDTOTraceabilitySystem,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **WHOLECHAIN** \
 * **NONE**
 */
export type TraceabilityProviderDTOTraceabilitySystem = string;

/** Known values of {@link Enum12} that the service accepts. */
export enum KnownEnum12 {
  /** Facility */
  Facility = "Facility",
  /** FacilityType */
  FacilityType = "FacilityType",
  /** Country */
  Country = "Country",
  /** BAPNumber */
  BAPNumber = "BAPNumber",
  /** Expiration */
  Expiration = "Expiration",
}

/**
 * Defines values for Enum12. \
 * {@link KnownEnum12} can be used interchangeably with Enum12,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Facility** \
 * **FacilityType** \
 * **Country** \
 * **BAPNumber** \
 * **Expiration**
 */
export type Enum12 = string;

/** Known values of {@link Enum13} that the service accepts. */
export enum KnownEnum13 {
  /** Asc */
  Asc = "Asc",
  /** Desc */
  Desc = "Desc",
}

/**
 * Defines values for Enum13. \
 * {@link KnownEnum13} can be used interchangeably with Enum13,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Asc** \
 * **Desc**
 */
export type Enum13 = string;

/** Known values of {@link PermissionItemPermission} that the service accepts. */
export enum KnownPermissionItemPermission {
  /** DownloadingAuditReports */
  DownloadingAuditReports = "DOWNLOADING_AUDIT_REPORTS",
  /** GrantAccess */
  GrantAccess = "GRANT_ACCESS",
  /** UserAdministration */
  UserAdministration = "USER_ADMINISTRATION",
  /** AccessAnalyticsReports */
  AccessAnalyticsReports = "ACCESS_ANALYTICS_REPORTS",
}

/**
 * Defines values for PermissionItemPermission. \
 * {@link KnownPermissionItemPermission} can be used interchangeably with PermissionItemPermission,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **DOWNLOADING_AUDIT_REPORTS** \
 * **GRANT_ACCESS** \
 * **USER_ADMINISTRATION** \
 * **ACCESS_ANALYTICS_REPORTS**
 */
export type PermissionItemPermission = string;

/** Known values of {@link NotificationDTOType} that the service accepts. */
export enum KnownNotificationDTOType {
  /** UserInvite */
  UserInvite = "USER_INVITE",
  /** EndorserUpdate */
  EndorserUpdate = "ENDORSER_UPDATE",
  /** AuditUpdate */
  AuditUpdate = "AUDIT_UPDATE",
  /** AuditDownload */
  AuditDownload = "AUDIT_DOWNLOAD",
  /** EndorserAccess */
  EndorserAccess = "ENDORSER_ACCESS",
  /** EndorserInvite */
  EndorserInvite = "ENDORSER_INVITE",
}

/**
 * Defines values for NotificationDTOType. \
 * {@link KnownNotificationDTOType} can be used interchangeably with NotificationDTOType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **USER_INVITE** \
 * **ENDORSER_UPDATE** \
 * **AUDIT_UPDATE** \
 * **AUDIT_DOWNLOAD** \
 * **ENDORSER_ACCESS** \
 * **ENDORSER_INVITE**
 */
export type NotificationDTOType = string;

/** Known values of {@link CreateRoleRequestPermissionItem} that the service accepts. */
export enum KnownCreateRoleRequestPermissionItem {
  /** DownloadingAuditReports */
  DownloadingAuditReports = "DOWNLOADING_AUDIT_REPORTS",
  /** GrantAccess */
  GrantAccess = "GRANT_ACCESS",
  /** UserAdministration */
  UserAdministration = "USER_ADMINISTRATION",
  /** AccessAnalyticsReports */
  AccessAnalyticsReports = "ACCESS_ANALYTICS_REPORTS",
}

/**
 * Defines values for CreateRoleRequestPermissionItem. \
 * {@link KnownCreateRoleRequestPermissionItem} can be used interchangeably with CreateRoleRequestPermissionItem,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **DOWNLOADING_AUDIT_REPORTS** \
 * **GRANT_ACCESS** \
 * **USER_ADMINISTRATION** \
 * **ACCESS_ANALYTICS_REPORTS**
 */
export type CreateRoleRequestPermissionItem = string;

/** Known values of {@link UpdateRoleRequestPermissionItem} that the service accepts. */
export enum KnownUpdateRoleRequestPermissionItem {
  /** DownloadingAuditReports */
  DownloadingAuditReports = "DOWNLOADING_AUDIT_REPORTS",
  /** GrantAccess */
  GrantAccess = "GRANT_ACCESS",
  /** UserAdministration */
  UserAdministration = "USER_ADMINISTRATION",
  /** AccessAnalyticsReports */
  AccessAnalyticsReports = "ACCESS_ANALYTICS_REPORTS",
}

/**
 * Defines values for UpdateRoleRequestPermissionItem. \
 * {@link KnownUpdateRoleRequestPermissionItem} can be used interchangeably with UpdateRoleRequestPermissionItem,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **DOWNLOADING_AUDIT_REPORTS** \
 * **GRANT_ACCESS** \
 * **USER_ADMINISTRATION** \
 * **ACCESS_ANALYTICS_REPORTS**
 */
export type UpdateRoleRequestPermissionItem = string;

/** Known values of {@link Enum18} that the service accepts. */
export enum KnownEnum18 {
  /** DownloadingAuditReports */
  DownloadingAuditReports = "DOWNLOADING_AUDIT_REPORTS",
  /** GrantAccess */
  GrantAccess = "GRANT_ACCESS",
  /** UserAdministration */
  UserAdministration = "USER_ADMINISTRATION",
  /** AccessAnalyticsReports */
  AccessAnalyticsReports = "ACCESS_ANALYTICS_REPORTS",
}

/**
 * Defines values for Enum18. \
 * {@link KnownEnum18} can be used interchangeably with Enum18,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **DOWNLOADING_AUDIT_REPORTS** \
 * **GRANT_ACCESS** \
 * **USER_ADMINISTRATION** \
 * **ACCESS_ANALYTICS_REPORTS**
 */
export type Enum18 = string;

/** Known values of {@link RoleItemPermissionItem} that the service accepts. */
export enum KnownRoleItemPermissionItem {
  /** DownloadingAuditReports */
  DownloadingAuditReports = "DOWNLOADING_AUDIT_REPORTS",
  /** GrantAccess */
  GrantAccess = "GRANT_ACCESS",
  /** UserAdministration */
  UserAdministration = "USER_ADMINISTRATION",
  /** AccessAnalyticsReports */
  AccessAnalyticsReports = "ACCESS_ANALYTICS_REPORTS",
}

/**
 * Defines values for RoleItemPermissionItem. \
 * {@link KnownRoleItemPermissionItem} can be used interchangeably with RoleItemPermissionItem,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **DOWNLOADING_AUDIT_REPORTS** \
 * **GRANT_ACCESS** \
 * **USER_ADMINISTRATION** \
 * **ACCESS_ANALYTICS_REPORTS**
 */
export type RoleItemPermissionItem = string;

/** Known values of {@link GetRoleByIdResponsePermissionsItem} that the service accepts. */
export enum KnownGetRoleByIdResponsePermissionsItem {
  /** DownloadingAuditReports */
  DownloadingAuditReports = "DOWNLOADING_AUDIT_REPORTS",
  /** GrantAccess */
  GrantAccess = "GRANT_ACCESS",
  /** UserAdministration */
  UserAdministration = "USER_ADMINISTRATION",
  /** AccessAnalyticsReports */
  AccessAnalyticsReports = "ACCESS_ANALYTICS_REPORTS",
}

/**
 * Defines values for GetRoleByIdResponsePermissionsItem. \
 * {@link KnownGetRoleByIdResponsePermissionsItem} can be used interchangeably with GetRoleByIdResponsePermissionsItem,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **DOWNLOADING_AUDIT_REPORTS** \
 * **GRANT_ACCESS** \
 * **USER_ADMINISTRATION** \
 * **ACCESS_ANALYTICS_REPORTS**
 */
export type GetRoleByIdResponsePermissionsItem = string;

/** Known values of {@link AssignRolesRequestEndorserRole} that the service accepts. */
export enum KnownAssignRolesRequestEndorserRole {
  /** Admin */
  Admin = "ADMIN",
  /** Employee */
  Employee = "EMPLOYEE",
}

/**
 * Defines values for AssignRolesRequestEndorserRole. \
 * {@link KnownAssignRolesRequestEndorserRole} can be used interchangeably with AssignRolesRequestEndorserRole,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **ADMIN** \
 * **EMPLOYEE**
 */
export type AssignRolesRequestEndorserRole = string;

/** Known values of {@link SearchResultType} that the service accepts. */
export enum KnownSearchResultType {
  /** Endorser */
  Endorser = "ENDORSER",
  /** Country */
  Country = "COUNTRY",
  /** Species */
  Species = "SPECIES",
  /** Product */
  Product = "PRODUCT",
  /** LOT */
  LOT = "LOT",
}

/**
 * Defines values for SearchResultType. \
 * {@link KnownSearchResultType} can be used interchangeably with SearchResultType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **ENDORSER** \
 * **COUNTRY** \
 * **SPECIES** \
 * **PRODUCT** \
 * **LOT**
 */
export type SearchResultType = string;

/** Known values of {@link CreateTraceabilityRequestTraceabilityProvider} that the service accepts. */
export enum KnownCreateTraceabilityRequestTraceabilityProvider {
  /** Wholechain */
  Wholechain = "WHOLECHAIN",
  /** None */
  None = "NONE",
}

/**
 * Defines values for CreateTraceabilityRequestTraceabilityProvider. \
 * {@link KnownCreateTraceabilityRequestTraceabilityProvider} can be used interchangeably with CreateTraceabilityRequestTraceabilityProvider,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **WHOLECHAIN** \
 * **NONE**
 */
export type CreateTraceabilityRequestTraceabilityProvider = string;

/** Known values of {@link EndorserAccessDTOTraceabilityProvider} that the service accepts. */
export enum KnownEndorserAccessDTOTraceabilityProvider {
  /** Wholechain */
  Wholechain = "WHOLECHAIN",
  /** None */
  None = "NONE",
}

/**
 * Defines values for EndorserAccessDTOTraceabilityProvider. \
 * {@link KnownEndorserAccessDTOTraceabilityProvider} can be used interchangeably with EndorserAccessDTOTraceabilityProvider,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **WHOLECHAIN** \
 * **NONE**
 */
export type EndorserAccessDTOTraceabilityProvider = string;

/** Known values of {@link UpdateUserRequestTraceabilityProvider} that the service accepts. */
export enum KnownUpdateUserRequestTraceabilityProvider {
  /** Wholechain */
  Wholechain = "WHOLECHAIN",
  /** None */
  None = "NONE",
}

/**
 * Defines values for UpdateUserRequestTraceabilityProvider. \
 * {@link KnownUpdateUserRequestTraceabilityProvider} can be used interchangeably with UpdateUserRequestTraceabilityProvider,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **WHOLECHAIN** \
 * **NONE**
 */
export type UpdateUserRequestTraceabilityProvider = string;

/** Known values of {@link UserDTOUserType} that the service accepts. */
export enum KnownUserDTOUserType {
  /** GSA */
  GSA = "GSA",
  /** Endorser */
  Endorser = "ENDORSER",
  /** Auditor */
  Auditor = "AUDITOR",
}

/**
 * Defines values for UserDTOUserType. \
 * {@link KnownUserDTOUserType} can be used interchangeably with UserDTOUserType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **GSA** \
 * **ENDORSER** \
 * **AUDITOR**
 */
export type UserDTOUserType = string;

/** Known values of {@link PostContentSchemaEndorserRole} that the service accepts. */
export enum KnownPostContentSchemaEndorserRole {
  /** Admin */
  Admin = "ADMIN",
  /** Employee */
  Employee = "EMPLOYEE",
}

/**
 * Defines values for PostContentSchemaEndorserRole. \
 * {@link KnownPostContentSchemaEndorserRole} can be used interchangeably with PostContentSchemaEndorserRole,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **ADMIN** \
 * **EMPLOYEE**
 */
export type PostContentSchemaEndorserRole = string;

/** Optional parameters. */
export interface RequestEndorserAccessOptionalParams
  extends coreClient.OperationOptions {
  body?: AccessRequest;
}

/** Contains response data for the requestEndorserAccess operation. */
export type RequestEndorserAccessResponse = AccessResponse;

/** Optional parameters. */
export interface GrantEndorserAccessOptionalParams
  extends coreClient.OperationOptions {
  body?: GrantAccessRequest;
}

/** Contains response data for the grantEndorserAccess operation. */
export type GrantEndorserAccessResponse = GrantAccessResponse;

/** Optional parameters. */
export interface ListGrantEndorserAccessOptionalParams
  extends coreClient.OperationOptions {
  body?: ListGrantAccessRequest;
}

/** Contains response data for the listGrantEndorserAccess operation. */
export type ListGrantEndorserAccessResponse = ListGrantAccessResponse;

/** Optional parameters. */
export interface RevokeAccessOptionalParams
  extends coreClient.OperationOptions {
  body?: RevokeAccessRequest;
}

/** Contains response data for the revokeAccess operation. */
export type RevokeAccessOperationResponse = RevokeAccessResponse;

/** Optional parameters. */
export interface AcceptInviteOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the acceptInvite operation. */
export type AcceptInviteResponse = UpdateAccessResponse;

/** Optional parameters. */
export interface RejectInviteOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the rejectInvite operation. */
export type RejectInviteResponse = UpdateAccessResponse;

/** Optional parameters. */
export interface ListEndorserAccessItemsOptionalParams
  extends coreClient.OperationOptions {
  /** Array of Get1ItemsItem */
  endorserName?: string[];
  /** Array of Get2ItemsItem */
  bapNumber?: string[];
}

/** Contains response data for the listEndorserAccessItems operation. */
export type ListEndorserAccessItemsResponse = ListAccessResponse;

/** Optional parameters. */
export interface ListMyEndorserAccessItemsOptionalParams
  extends coreClient.OperationOptions {
  /** Array of Get0ItemsItem */
  endorserName?: string[];
  /** Array of String */
  bapNumber?: string[];
}

/** Contains response data for the listMyEndorserAccessItems operation. */
export type ListMyEndorserAccessItemsResponse = ListMyAccessResponse;

/** Optional parameters. */
export interface ListAccountsOptionalParams
  extends coreClient.OperationOptions {
  bapNumber?: string;
  facility?: string;
  childAccessFacility?: string;
  /** Array of Get3ItemsItem */
  facilityTypes?: string[];
  /** Array of Get4ItemsItem */
  countries?: string[];
  expiration?: Date;
  /** Array of Get6ItemsItem */
  species?: string[];
  order?: Enum0;
  direction?: Enum1;
  hideSelf?: boolean;
  /** Array of Get10ItemsItem */
  excludeIds?: string[];
  pageNumber?: number;
  pageSize?: number;
}

/** Contains response data for the listAccounts operation. */
export type ListAccountsOperationResponse = ListAccountsResponse;

/** Optional parameters. */
export interface ListAuditReportsOptionalParams
  extends coreClient.OperationOptions {
  bapNumber?: string;
  pageNumber?: number;
  pageSize?: number;
  facilityName?: string;
  /** Array of String */
  status?: string[];
  submittedDate?: string;
  orderBy?: Enum3;
  direction?: Enum4;
}

/** Contains response data for the listAuditReports operation. */
export type ListAuditReportsOperationResponse = ListAuditReportsResponse;

/** Optional parameters. */
export interface GetAuditReportByIdOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getAuditReportById operation. */
export type GetAuditReportByIdOperationResponse = GetAuditReportByIdResponse;

/** Optional parameters. */
export interface GetAssessmentAnswersOptionalParams
  extends coreClient.OperationOptions {
  pageNumber?: number;
  pageSize?: number;
  referenceNumber?: string;
  /** Array of String */
  pillars?: string[];
  /** Array of String */
  bAPNumbers?: string[];
  /** Array of Get5ItemsItem */
  phases?: string[];
  /** Array of String */
  answers?: string[];
  causeExplanation?: string;
  order?: Enum5;
  direction?: Enum6;
}

/** Contains response data for the getAssessmentAnswers operation. */
export type GetAssessmentAnswersOperationResponse =
  GetAssessmentAnswersResponse;

/** Optional parameters. */
export interface ExportReportOptionalParams
  extends coreClient.OperationOptions {
  referenceNumber?: string;
  /** Array of String */
  pillars?: string[];
  /** Array of String */
  answers?: string[];
  causeExplanation?: string;
  order?: Enum7;
  direction?: Enum8;
  summary?: boolean;
  fullReport?: boolean;
}

/** Contains response data for the exportReport operation. */
export type ExportReportResponse = WebAPIClientExportReportHeaders & {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always `undefined` in node.js.
   */
  blobBody?: Promise<Blob>;
  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always `undefined` in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;
};

/** Optional parameters. */
export interface GetFirstPageByIdOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getFirstPageById operation. */
export type GetFirstPageByIdResponse = WebAPIClientGetFirstPageByIdHeaders & {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always `undefined` in node.js.
   */
  blobBody?: Promise<Blob>;
  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always `undefined` in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;
};

/** Optional parameters. */
export interface ListFacilityLocationsOptionalParams
  extends coreClient.OperationOptions {
  pageNumber?: number;
  pageSize?: number;
}

/** Contains response data for the listFacilityLocations operation. */
export type ListFacilityLocationsOperationResponse =
  ListFacilityLocationsResponse;

/** Optional parameters. */
export interface ListEndorsersOptionalParams
  extends coreClient.OperationOptions {
  bapNumber?: string;
  facility?: string;
  childAccessFacility?: string;
  /** Array of String */
  facilityTypes?: string[];
  /** Array of String */
  countries?: string[];
  expiration?: Date;
  /** Array of Get7ItemsItem */
  species?: string[];
  hideSelf?: boolean;
  /** Array of Get13ItemsItem */
  excludeIds?: string[];
  pageNumber?: number;
  pageSize?: number;
  /** Array of String */
  partnerTypes?: string[];
  /** Array of Get8ItemsItem */
  eSA?: boolean[];
  /** Array of Get9ItemsItem */
  announceType?: string[];
  order?: Enum9;
  direction?: Enum10;
}

/** Contains response data for the listEndorsers operation. */
export type ListEndorsersOperationResponse = ListEndorsersResponse;

/** Optional parameters. */
export interface GetEndorserByIdOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getEndorserById operation. */
export type GetEndorserByIdOperationResponse = GetEndorserByIdResponse;

/** Optional parameters. */
export interface ListNetworkOptionalParams extends coreClient.OperationOptions {
  bapNumber?: string;
  facility?: string;
  /** Array of String */
  facilityTypes?: string[];
  /** Array of String */
  countries?: string[];
  expiration?: Date;
  /** Array of String */
  species?: string[];
  pageNumber?: number;
  pageSize?: number;
  /** Array of String */
  partnerTypes?: string[];
  /** Array of Boolean */
  eSA?: boolean[];
  /** Array of String */
  announceType?: string[];
  order?: Enum12;
  direction?: Enum13;
}

/** Contains response data for the listNetwork operation. */
export type ListNetworkOperationResponse = ListNetworkResponse;

/** Optional parameters. */
export interface SignJwtOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the signJwt operation. */
export type SignJwtOperationResponse = SignJwtResponse;

/** Optional parameters. */
export interface ListCountriesOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the listCountries operation. */
export type ListCountriesOperationResponse = ListCountriesResponse;

/** Optional parameters. */
export interface ListSpeciesOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the listSpecies operation. */
export type ListSpeciesOperationResponse = ListSpeciesResponse;

/** Optional parameters. */
export interface ListPermissionsOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the listPermissions operation. */
export type ListPermissionsOperationResponse = ListPermissionsResponse;

/** Optional parameters. */
export interface ListNotificationsOptionalParams
  extends coreClient.OperationOptions {
  pageNumber?: number;
  pageSize?: number;
  recentActivity?: boolean;
  from?: Date;
  to?: Date;
}

/** Contains response data for the listNotifications operation. */
export type ListNotificationsOperationResponse = ListNotificationsResponse;

/** Optional parameters. */
export interface ClearNotificationsOptionalParams
  extends coreClient.OperationOptions {
  body?: ClearNotificationsRequest;
}

/** Contains response data for the clearNotifications operation. */
export type ClearNotificationsOperationResponse = ClearNotificationsResponse;

/** Optional parameters. */
export interface NotifyHelpDeskOptionalParams
  extends coreClient.OperationOptions {
  body?: NotifyHelpDeskRequest;
}

/** Contains response data for the notifyHelpDesk operation. */
export type NotifyHelpDeskResponse = Record<string, unknown>;

/** Optional parameters. */
export interface ListProductsOptionalParams
  extends coreClient.OperationOptions {
  digitalLink?: string;
}

/** Contains response data for the listProducts operation. */
export type ListProductsOperationResponse = ListProductsResponse;

/** Optional parameters. */
export interface ListReportsOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the listReports operation. */
export type ListReportsOperationResponse = ListReportsResponse;

/** Optional parameters. */
export interface CreateRoleOptionalParams extends coreClient.OperationOptions {
  body?: CreateRoleRequest;
}

/** Contains response data for the createRole operation. */
export type CreateRoleResponse = CreateUpdateRoleResponse;

/** Optional parameters. */
export interface UpdateRoleOptionalParams extends coreClient.OperationOptions {
  body?: UpdateRoleRequest;
}

/** Contains response data for the updateRole operation. */
export type UpdateRoleResponse = CreateUpdateRoleResponse;

/** Optional parameters. */
export interface ListRolesOptionalParams extends coreClient.OperationOptions {
  roleName?: string;
  /** Array of paths·1nw3hn2·roles·get·parameters·1·schema·items */
  permissions?: Enum18[];
}

/** Contains response data for the listRoles operation. */
export type ListRolesOperationResponse = ListRolesResponse;

/** Optional parameters. */
export interface GetRoleByIdOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getRoleById operation. */
export type GetRoleByIdOperationResponse = GetRoleByIdResponse;

/** Optional parameters. */
export interface DeleteRoleOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the deleteRole operation. */
export type DeleteRoleOperationResponse = DeleteRoleResponse;

/** Optional parameters. */
export interface AssignRolesOptionalParams extends coreClient.OperationOptions {
  body?: AssignRolesRequest;
}

/** Contains response data for the assignRoles operation. */
export type AssignRolesOperationResponse = AssignRolesResponse;

/** Optional parameters. */
export interface SearchOptionalParams extends coreClient.OperationOptions {
  pageNumber?: number;
  pageSize?: number;
  term?: string;
}

/** Contains response data for the search operation. */
export type SearchOperationResponse = SearchResponse;

/** Optional parameters. */
export interface GetSearchHistoryOptionalParams
  extends coreClient.OperationOptions {
  pageNumber?: number;
  pageSize?: number;
}

/** Contains response data for the getSearchHistory operation. */
export type GetSearchHistoryResponse = ListSearchHistoryByUserIdResponse;

/** Optional parameters. */
export interface ListEventsOptionalParams extends coreClient.OperationOptions {
  traceabilityId?: string;
  gtin?: string;
  primaryId?: string;
}

/** Contains response data for the listEvents operation. */
export type ListEventsOperationResponse = ListEventsResponse;

/** Optional parameters. */
export interface MapEventsOptionalParams extends coreClient.OperationOptions {
  traceabilityId?: string;
  gtin?: string;
  primaryId?: string;
}

/** Contains response data for the mapEvents operation. */
export type MapEventsResponse = MapResponse;

/** Optional parameters. */
export interface DiagramEventsOptionalParams
  extends coreClient.OperationOptions {
  traceabilityId?: string;
  gtin?: string;
  primaryId?: string;
}

/** Contains response data for the diagramEvents operation. */
export type DiagramEventsResponse = DiagramResponse;

/** Optional parameters. */
export interface ListTracebilitiesOptionalParams
  extends coreClient.OperationOptions {
  /** Any object */
  request?: Record<string, unknown>;
}

/** Contains response data for the listTracebilities operation. */
export type ListTracebilitiesResponse = ListTraceabilityProviderResponse;

/** Optional parameters. */
export interface CreateTraceabilityProviderOptionalParams
  extends coreClient.OperationOptions {
  body?: CreateTraceabilityRequest;
}

/** Contains response data for the createTraceabilityProvider operation. */
export type CreateTraceabilityProviderResponse = CreateTraceabilityResponse;

/** Optional parameters. */
export interface UpdateTraceabilityProviderOptionalParams
  extends coreClient.OperationOptions {
  body?: UpdateTraceabilityRequest;
}

/** Contains response data for the updateTraceabilityProvider operation. */
export type UpdateTraceabilityProviderResponse = UpdateTraceabilityResponse;

/** Optional parameters. */
export interface ListEndorsersWithTraceabilityOptionalParams
  extends coreClient.OperationOptions {
  pageNumber?: number;
  pageSize?: number;
}

/** Contains response data for the listEndorsersWithTraceability operation. */
export type ListEndorsersWithTraceabilityResponse =
  ListEndorsersTraceabilityResponse;

/** Optional parameters. */
export interface DeleteTraceabilityOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteTraceability operation. */
export type DeleteTraceabilityOperationResponse = DeleteTraceabilityResponse;

/** Optional parameters. */
export interface UpdateUserOptionalParams extends coreClient.OperationOptions {
  body?: UpdateUserRequest;
}

/** Contains response data for the updateUser operation. */
export type UpdateUserOperationResponse = UpdateUserResponse;

/** Optional parameters. */
export interface MeOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the me operation. */
export type MeOperationResponse = MeResponse;

/** Optional parameters. */
export interface ListUsersOptionalParams extends coreClient.OperationOptions {
  email?: string;
  name?: string;
  /** Array of String */
  roleName?: string[];
}

/** Contains response data for the listUsers operation. */
export type ListUsersOperationResponse = ListUsersResponse;

/** Optional parameters. */
export interface GetUserByIdOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getUserById operation. */
export type GetUserByIdOperationResponse = GetUserByIdResponse;

/** Optional parameters. */
export interface DeleteUserOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the deleteUser operation. */
export type DeleteUserOperationResponse = DeleteUserResponse;

/** Optional parameters. */
export interface UploadProfilePictureOptionalParams
  extends coreClient.OperationOptions {
  picture?: coreRestPipeline.RequestBodyType;
}

/** Contains response data for the uploadProfilePicture operation. */
export type UploadProfilePictureOperationResponse =
  UploadProfilePictureResponse;

/** Optional parameters. */
export interface InviteOptionalParams extends coreClient.OperationOptions {
  /** Array of PostContentSchemaEmailsItem */
  emails?: string[];
  message?: string;
  roleId?: string;
  accountId?: string;
  endorserRole?: PostContentSchemaEndorserRole;
}

/** Contains response data for the invite operation. */
export type InviteResponse = InviteUserResponse;

/** Optional parameters. */
export interface WebAPIClientOptionalParams
  extends coreClient.ServiceClientOptions {
  /** Overrides client endpoint. */
  endpoint?: string;
}
