import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
} from 'antd';
import Password from 'antd/lib/input/Password';
import { DefaultOptionType } from 'antd/lib/select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KnownTraceabilityProviderDTOTraceabilitySystem } from 'services/api/client/src';
import {
  ActiveBadge,
  DeleteBtnStyle,
  errorHandler,
  InActiveBadge,
  PrimaryBtnStyle,
  SecondaryBtnStyle,
} from 'utils';

import { useQueryClient } from 'react-query';
import {
  useCreateConnection,
  useDeleteConnection,
  useUpdateConnection,
} from 'services/api/useTraceability';
import GTINTable from './GTINTable';
import styles from './index.module.less';
import { ConnectionModalProps, ConnectionTabProps, GTINColumn } from './typings';

const { Title } = Typography;

const ConnectionModal = ({ modal }: ConnectionModalProps) => {
  const queryClient = useQueryClient();
  const createConnection = useCreateConnection(queryClient);
  const updateConnection = useUpdateConnection(queryClient);
  const deleteConnection = useDeleteConnection(queryClient);
  const [isLoading, setIsLoading] = useState(false);

  const changeTabs = (key: string) => {
    if (key === 'connection' || key === 'gtins') {
      modal.setStep(key);
    }
  };

  const { t } = useTranslation('pages', { keyPrefix: 'trace.connection_modal' });
  const [gtins, setGtins] = useState<Array<GTINColumn>>([]);
  const ModalBodyStyle = { height: '60%' };
  const traceabilityOptions: Array<DefaultOptionType> = useMemo(
    () => [
      { label: 'Wholechain', value: KnownTraceabilityProviderDTOTraceabilitySystem.Wholechain },
      { label: 'Other', value: KnownTraceabilityProviderDTOTraceabilitySystem.None },
    ],
    [],
  );

  useEffect(() => {
    if (modal.mode === 'edit' && modal.traceabilityProvider) {
      // Pre-populate the form with values from the state
      modal.form.setFieldsValue({
        digitalLink: modal.traceabilityProvider.digital_url,
        connectionName: modal.traceabilityProvider.connection_name,
        traceabilityProvider:
          traceabilityOptions
            .filter((opt) => opt.label === modal.traceabilityProvider!.traceability_system)[0]
            .value?.toString() || undefined,
        gtins: modal.traceabilityProvider.gtins?.split('\r\n'),
      });
      setGtins(
        modal.traceabilityProvider.gtins?.split('\r\n')?.map((gtin, index) => ({
          index,
          gtin,
          id: `${index}`,
        })) || [],
      );
    }
  }, [modal.mode, modal.visible, modal.traceabilityProvider, modal.form, traceabilityOptions]);

  const closeModal = useCallback(() => {
    modal.setMode('create');
    modal.setStep('connection');
    modal.setVisible(false);
    setGtins([]);
    modal.form.resetFields();
  }, [modal]);

  const del = useCallback(() => {
    Modal.confirm({
      title: t('delete_connection_modal.title'),
      content: t('delete_connection_modal.message_1'),
      icon: <ExclamationCircleOutlined className={styles.deletebtnicon} />,
      okText: t('delete_connection_modal.ok_text'),
      cancelText: t('delete_connection_modal.cancel_text'),
      centered: true,
      width: '30%',
      okButtonProps: {
        type: 'primary',
        shape: 'round',
        size: 'middle',
        style: DeleteBtnStyle,
        loading: isLoading,
      },
      cancelButtonProps: {
        shape: 'round',
        size: 'middle',
        style: SecondaryBtnStyle,
      },
      onOk: async () => {
        setIsLoading(true);
        try {
          await deleteConnection.mutateAsync(modal.traceabilityProvider?.id || '');
          setIsLoading(false);
          message.success(t('connection_deleted_success_messsage'));
          closeModal();
        } catch (error) {
          setIsLoading(false);
          message.error(errorHandler(error));
          closeModal();
        }
      },
    });
  }, [closeModal, deleteConnection, isLoading, modal.traceabilityProvider?.id, t]);

  const save = useCallback(async () => {
    modal.form.validateFields().then(async () => {
      if (modal.mode === 'create' && modal.step === 'connection') {
        modal.setStep('gtins');
      } else {
        switch (modal.mode) {
          case 'create':
            try {
              await createConnection.mutateAsync({
                apiKey: modal.form.getFieldValue('apiKey'),
                gtins: gtins.map((item) => item.gtin ?? ''),
                digitalLink: modal.form.getFieldValue('digitalLink'),
                connectionName: modal.form.getFieldValue('connectionName'),
              });
            } catch (err) {
              message.error(errorHandler(err));
              return;
            }
            message.success(t('connection_created_success_messsage'));
            closeModal();
            break;
          case 'edit':
            try {
              await updateConnection.mutateAsync({
                traceabilityId: modal.traceabilityProvider!.id,
                apiKey: modal.form.getFieldValue('apiKey'),
                gtins: gtins.map((item) => item.gtin ?? ''),
                digitalLink: modal.form.getFieldValue('digitalLink'),
                connectionName: modal.form.getFieldValue('connectionName'),
              });
            } catch (err) {
              message.error(errorHandler(err));
              return;
            }
            message.success(
              `${t('connection_edited_success_messsage')} ${modal.form.getFieldValue(
                'connectionName',
              )}`,
            );
            closeModal();
            break;
          default:
            break;
        }
      }
    });
  }, [createConnection, gtins, modal, closeModal, t, updateConnection]);

  const TabTitle = useCallback(
    ({ title, count, active }: ConnectionTabProps) => (
      <Space>
        {title}
        <Badge count={count} style={active ? { ...ActiveBadge } : { ...InActiveBadge }} />
      </Space>
    ),
    [],
  );

  const ModalTitle = useMemo(
    () => (
      <Row gutter={0}>
        <Col className={styles.title}>
          <Title level={4}>
            {modal.step === 'gtins' && (
              <span
                className={styles.backButton}
                role="button"
                tabIndex={0}
                onClick={() => modal.setStep('connection')}
                onKeyDown={(e) => {
                  if (e.key === 'ArrowLeft') {
                    modal.setStep('connection');
                  }
                }}
              >
                <LeftOutlined className={styles.backIcon} />
              </span>
            )}
            {(modal.step === 'gtins' && t('add_gtins')) ||
              (modal.step === 'connection' && modal.mode === 'create'
                ? t('add_connection')
                : t('edit_connection'))}
          </Title>
        </Col>
      </Row>
    ),
    [modal, t],
  );

  const Footer = useMemo(
    () => (
      <>
        {modal.mode === 'edit' && (
          <Button
            shape="round"
            style={{ ...SecondaryBtnStyle, float: 'left', border: '0px' }}
            onClick={() => del()}
          >
            <DeleteOutlined />
            {t('remove_connection_button')}
          </Button>
        )}
        <Button shape="round" style={SecondaryBtnStyle} onClick={closeModal}>
          {t('cancel')}
        </Button>
        <Button shape="round" style={PrimaryBtnStyle} onClick={save}>
          {modal.mode === 'create' && modal.step === 'connection' ? t('next') : t('connect')}
        </Button>
      </>
    ),
    [modal.mode, modal.step, t, closeModal, save, del],
  );

  return (
    <Modal
      visible={modal.visible}
      title={ModalTitle}
      closable
      onCancel={closeModal}
      width="58%"
      bodyStyle={ModalBodyStyle}
      centered
      footer={Footer}
    >
      {modal.mode === 'edit' && (
        <Tabs className={styles.tabs} defaultActiveKey="connection_tab" onChange={changeTabs}>
          <Tabs.TabPane
            tab={<TabTitle title={t('connection_tab')} active={modal.step === 'connection'} />}
            key="connection"
          />
          <Tabs.TabPane
            tab={<TabTitle title={t('gtin_tab')} active={modal.step === 'gtins'} />}
            key="gtins"
          />
        </Tabs>
      )}
      <Form form={modal.form} name="traceability" scrollToFirstError layout="vertical">
        {modal.step === 'connection' && (
          <>
            <div className={styles.headerText}>{t('header_text')}</div>

            <Form.Item label={t('connection_name_label')} name="connectionName">
              <Input placeholder={t('connection_name_label')} />
            </Form.Item>

            <Form.Item
              label={t('system_label')}
              name="traceabilityProvider"
              rules={[
                {
                  required: true,
                  message: t('system_required'),
                },
              ]}
            >
              <Select
                placeholder={
                  <Typography.Text className={styles.placeholder}>
                    {t('system_label')}
                  </Typography.Text>
                }
                options={traceabilityOptions}
              />
            </Form.Item>
            <Form.Item
              label={t('digital_link_label')}
              name="digitalLink"
              tooltip={{
                title: t('digital_link_info'),
                icon: <QuestionCircleOutlined />,
                placement: 'topLeft',
              }}
              rules={[
                {
                  required: true,
                  message: t('digital_link_required'),
                  type: 'url',
                },
              ]}
            >
              <Input placeholder={t('digital_link_label')} />
            </Form.Item>

            <Form.Item
              label={t('api_key_label')}
              name="apiKey"
              tooltip={{
                title: t('api_key_info'),
                icon: <QuestionCircleOutlined />,
                placement: 'topLeft',
              }}
              rules={[
                {
                  required: modal.mode === 'create',
                  message: t('api_key_required'),
                },
              ]}
            >
              <Password placeholder={t('api_key_label')} />
            </Form.Item>
          </>
        )}
        {modal.step === 'gtins' && (
          <>
            <div className={styles.headerText}>
              <p>
                {t('gtins_header_text')}
                <a
                  className={styles.hyperLink}
                  href="https://www.gs1.org/docs/idkeys/GS1_GTIN_Executive_Summary.pdf"
                >
                  {t('click_here')}
                </a>
                {t('gtins_header_text2')}
              </p>
            </div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('gtin_required'),
                },
              ]}
            >
              <GTINTable
                gtins={gtins}
                setGtins={setGtins}
                addLabel={t('add_gtin')}
                uploadLabel={t('upload_gtins')}
                deleteLabel={t('delete_gtin')}
                editLabel={t('edit_gtin')}
                gtinRequiredMessage={t('gtin_required')}
                form={modal.form}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ConnectionModal;
