import type { ActionType } from '@ant-design/pro-table';
import { ProColumns } from '@ant-design/pro-table';
import { Space, Tag } from 'antd';
import GTable from 'components/GTable';
import { FC, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { dateFormat } from 'utils/helpers';
import styles from './index.module.less';
import { ModalContentProps, ModalItem, ModalTitleProp } from './typings';

type ActionItemsProps = {
  t: TFunction<'pages', 'trace.trace_diagram'>;
};
const columns = ({ t }: ActionItemsProps): ProColumns<ModalItem>[] => [
  {
    title: t('modal.attributes'),
    dataIndex: 'attributes',
    width: '30%',
  },
  {
    title: t('modal.values'),
    dataIndex: 'values',
    render: (text) => text,
  },
];

const ModalTitle: FC<ModalTitleProp> = ({ selectedLotData, selectedEventData }) => (
  <Space size={0}>
    <Tag className={styles.headertag}>{selectedEventData?.eventType}</Tag>
    <Tag className={styles.headertag}>{`Lot: ${selectedLotData?.lotId}`}</Tag>
  </Space>
);

const EventDetailsContent = ({
  lot: selectedLotData,
  event: selectedEventData,
}: ModalContentProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_diagram',
  });
  const actionRef = useRef<ActionType>();
  const attributesArray: any = [
    t('modal.event_date'),
    t('modal.gtin'),
    t('modal.product'),
    t('modal.primary_id'),
    t('modal.quantity'),
    t('modal.location'),
  ];

  const valuesArray: any = [
    dateFormat(selectedEventData?.eventTime || ''),
    selectedLotData?.gtin ?? selectedLotData?.epc,
    selectedLotData?.shortDescription,
    selectedLotData?.lotId,
    selectedLotData?.quantity,
    selectedEventData?.location?.name,
  ];

  const eventData: any = attributesArray.map((item: string, idx: number) => ({
    id: idx.toString(),
    attributes: item,
    values: valuesArray[idx],
  }));

  return (
    <div style={{ height: '500px', overflow: 'y' }}>
      <GTable<ModalItem>
        columns={columns({ t })}
        actionRef={actionRef}
        headerTitle={
          <ModalTitle selectedLotData={selectedLotData} selectedEventData={selectedEventData} />
        }
        value={eventData}
        options={{
          setting: false,
          reload: false,
        }}
        scroll={{ y: 410, x: 500 }}
      />
    </div>
  );
};

export default EventDetailsContent;
