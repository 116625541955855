import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ProPageHeader } from '@ant-design/pro-layout';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Button, message, Modal, Typography } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import { GTable } from 'components';
import EndorserActions from 'components/Actions/EndorserActions';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { TraceabilityProviderDTO } from 'services/api/client/src';
import { useDeleteConnection } from 'services/api/useTraceability';
import { DeleteBtnStyle, errorHandler, SecondaryBtnStyle } from 'utils';
import useConnectionListFilters, { useConnectionModal } from '../../hooks';
import ConnectionModal from '../ConnectionModal';
import styles from './index.module.less';
import { TraceabilityTableHeaderProps, TraceabilityTableProps } from './typings';

export interface TraceabilityColumns {
  id?: string;
  connection_name?: string;
  digital_url?: string;
  api_key?: string;
  traceability_system?: string;
  gtins?: string;
}

type ActionItemsProps = {
  t: TFunction<'pages', 'settings.traceability_table'>;
};

const traceabilityActionItems = ({ t }: ActionItemsProps): Array<ItemType> => [
  {
    key: 'edit',
    label: t('item_actions.edit_connection'),
  },
  {
    key: 'delete',
    label: t('item_actions.delete'),
  },
];

type ColumnsType = {
  onRowActionClick: (actionItemKey: string, actionPayload?: TraceabilityColumns) => void;
};

const columns = (
  { onRowActionClick }: ColumnsType,
  t: TFunction<'pages', 'settings.traceability_table'>,
): Array<ProColumns<TraceabilityColumns>> => [
  {
    title: t('connection_name'),
    dataIndex: 'connection_name',
    tooltip: {
      title: t('connection_name_info'),
      icon: <QuestionCircleOutlined />,
      placement: 'topLeft',
    },
    ellipsis: true,
  },
  {
    title: t('gtin'),
    dataIndex: 'gtins',
    valueType: 'text',
    render: (text) => <pre style={{ marginBottom: '0px' }}>{text}</pre>,
  },
  {
    title: t('traceability_system'),
    dataIndex: 'traceability_system',
    ellipsis: true,
  },
  {
    title: t('digital_link'),
    dataIndex: 'digital_url',
    tooltip: {
      title: t('digital_link_url_info'),
      icon: <QuestionCircleOutlined />,
      placement: 'topLeft',
    },
    ellipsis: true,
  },
  {
    title: '',
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    align: 'center',
    render: (text, record) => (
      <EndorserActions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        size="small"
        items={traceabilityActionItems({ t })}
        onClick={onRowActionClick}
        actionButtonColor={SecondaryBtnStyle}
        actionPayload={record}
      />
    ),
    fixed: 'right',
    width: '150px',
  },
];

const TraceabilityTableHeader = ({ t, connectionModal }: TraceabilityTableHeaderProps) => {
  const addConnection = useCallback(() => {
    connectionModal.setVisible(true);
  }, [connectionModal]);

  const AddConnectionBtn = useMemo(
    () => (
      <Button
        type="primary"
        shape="round"
        size="middle"
        className={classNames(styles.addconnection)}
        ghost={false}
        onClick={addConnection}
      >
        {t('header.add_connection')}
      </Button>
    ),
    [addConnection, t],
  );
  return (
    <>
      <ProPageHeader
        prefixedClassName="titlebar"
        title={t('header.traceability')}
        className={styles['page-header']}
        extra={AddConnectionBtn}
      />
      <div className={styles.headertext}>{t('header.traceability_header_message')}</div>
      <br />
      <Typography.Text>{t('header.my_connections')}</Typography.Text>
    </>
  );
};

const TraceabilityTable: FC<TraceabilityTableProps> = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.traceability_table' });
  const queryClient = useQueryClient();
  const traceabilityTableItemRef = useRef<ActionType>();

  const { connectionsList, isLoading } = useConnectionListFilters();
  const deleteConnection = useDeleteConnection(queryClient);
  const connectionModal = useConnectionModal();

  const connectionList: Array<TraceabilityColumns> = useMemo(
    () =>
      connectionsList?.traceabilityProviders?.map((item: TraceabilityProviderDTO) => ({
        id: item?.traceabilityProviderId,
        digital_url: item?.digitalLink,
        traceability_system: item?.traceabilitySystem === 'WHOLECHAIN' ? 'Wholechain' : 'Other',
        connection_name: item?.connectionName,
        gtins: item?.gtins?.map((gtin) => gtin).join('\r\n'),
      })) || [],
    [connectionsList],
  );

  const [isDeleting, setIsDeleting] = useState(false);
  const onDelRow = async (actionPayload?: TraceabilityColumns) => {
    Modal.confirm({
      title: t('delete_connection_modal.title'),
      content:
        connectionList.length === 1
          ? t('delete_connection_modal.message_1')
          : t('delete_connection_modal.message_2'),
      icon: <ExclamationCircleOutlined className={styles.deletebtnicon} />,
      okText: t('delete_connection_modal.ok_text'),
      cancelText: t('delete_connection_modal.cancel_text'),
      centered: true,
      width: '30%',
      okButtonProps: {
        type: 'primary',
        shape: 'round',
        size: 'middle',
        style: DeleteBtnStyle,
        loading: isDeleting,
      },
      cancelButtonProps: {
        shape: 'round',
        size: 'middle',
        style: SecondaryBtnStyle,
      },
      onOk: async () => {
        setIsDeleting(true);
        try {
          await deleteConnection.mutateAsync(actionPayload?.id || '');
          setIsDeleting(false);
          message.success(t('connection_deleted_success_messsage'));
        } catch (error) {
          setIsDeleting(false);
          message.error(errorHandler(error));
        }
      },
    });
  };

  const onRowActionClick = async (actionItemKey: string, actionPayload?: TraceabilityColumns) => {
    switch (actionItemKey) {
      case 'edit':
        connectionModal.show(actionPayload);
        break;
      case 'delete':
        onDelRow(actionPayload);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <GTable<TraceabilityColumns>
        key="TraceabilityTable"
        actionRef={traceabilityTableItemRef}
        columns={columns({ onRowActionClick }, t)}
        value={connectionList}
        loading={isLoading}
        options={{
          setting: false,
          reload: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        headerTitle={<TraceabilityTableHeader t={t} connectionModal={connectionModal} />}
      />
      <ConnectionModal modal={connectionModal} />
    </>
  );
};

export default TraceabilityTable;
