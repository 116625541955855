import { Button, Col, Modal, Row, Space, Typography } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import EventDetailsContent from '../../EventDetailsContent';
import { ListModalProps } from './typings';

const { Title } = Typography;

const ListModal = ({ selectedLotData, selectedEventData, modal }: ListModalProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_diagram',
  });

  const onCloseModal = () => {
    modal.hide();
  };

  const renderSection = useCallback(
    () => <EventDetailsContent lot={selectedLotData} event={selectedEventData} />,
    [selectedLotData, selectedEventData],
  );

  return (
    <Modal
      visible={modal.visible}
      title={
        <Row gutter={0}>
          <Col style={{ padding: '4px 2px' }}>
            <Title level={4}>{selectedLotData?.shortDescription}</Title>
          </Col>
        </Row>
      }
      closable
      onCancel={onCloseModal}
      footer={
        <Space>
          <Button
            onClick={onCloseModal}
            type="primary"
            shape="round"
            style={{
              color: '#0a5f7a',
              borderColor: '#bcd530',
              background: '#bcd530',
            }}
          >
            {t('modal.done')}
          </Button>
        </Space>
      }
      width="58%"
      bodyStyle={{ height: '60%' }}
      centered
    >
      {renderSection()}
    </Modal>
  );
};

export default ListModal;
