/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EventDetails,
  Header,
  TraceDiagram,
  TraceList,
  TraceMap,
} from 'components/GTrace/components';
import { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useEventDiagram, useEventList, useEventMap } from 'services/api/useTraceability';
import styles from '../../index.module.less';
import { TraceSectionsProps } from './typings';

const TraceSections: FC<TraceSectionsProps> = ({ traceTab, setTraceTab, searchValue }) => {
  const queryClient = useQueryClient();
  const { data: mapData, isLoading: isMapLoading } = useEventMap(queryClient, {
    gtin: searchValue.gtin,
    traceabilityId: searchValue.traceabilityProviderId,
    primaryId: searchValue.primaryId,
  });

  const { data: diagramData, isLoading: isDiagramLoading } = useEventDiagram({
    gtin: searchValue.gtin,
    traceabilityId: searchValue.traceabilityProviderId,
    primaryId: searchValue.primaryId,
  });

  const { data: listData, isLoading: isListLoading } = useEventList({
    gtin: searchValue.gtin,
    traceabilityId: searchValue.traceabilityProviderId,
    primaryId: searchValue.primaryId,
  });

  return (
    <div className={styles.container}>
      <Header
        productName={mapData?.productName}
        isAllEventsListLoading={isMapLoading}
        // Switch buttons
        traceTab={traceTab}
        setTraceTab={setTraceTab}
      />
      {traceTab === 'list' ? (
        <TraceList
          // api data
          allEventsList={listData?.events}
          isAllEventsListLoading={false}
        />
      ) : null}
      {traceTab === 'event_details' ? <EventDetails /> : null}
      {traceTab === 'map' ? (
        <TraceMap
          // event map api data
          mapData={mapData?.map}
          isLoading={isMapLoading}
        />
      ) : null}
      {traceTab === 'diagram' ? (
        <TraceDiagram
          // event diagram data
          eventDiagramNodes={diagramData?.diagram}
          isLoading={isDiagramLoading}
        />
      ) : null}
    </div>
  );
};

export default TraceSections;
