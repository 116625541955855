import { BetaSchemaForm } from '@ant-design/pro-form';
import React, { FC } from 'react';
import TraceabilityTableFields, {
  DataItem,
} from './components/TraceabilityTable/TraceabilityTable.fields';
import { TraceabilityPageProps } from './typings';

const Traceability: FC<TraceabilityPageProps> = ({ form }) => (
  <BetaSchemaForm<DataItem>
    columns={TraceabilityTableFields()}
    form={form}
    grid
    layoutType="Form"
    submitter={{ render: () => null }}
  />
);

export default React.memo(Traceability);
