import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  CreateTraceabilityProviderResponse,
  CreateTraceabilityRequest,
  DeleteTraceabilityResponse,
  DiagramEventsOptionalParams,
  DiagramEventsResponse,
  GetSearchHistoryOptionalParams,
  GetSearchHistoryResponse,
  ListEndorsersWithTraceabilityOptionalParams,
  ListEndorsersWithTraceabilityResponse,
  ListEventsOptionalParams,
  ListEventsResponse,
  ListProductsOperationResponse,
  ListProductsOptionalParams,
  ListTracebilitiesOptionalParams,
  ListTracebilitiesResponse,
  MapEventsOptionalParams,
  MapResponse,
  UpdateTraceabilityProviderResponse,
  UpdateTraceabilityRequest,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

// connection list
const connectionsList = async (
  params?: ListTracebilitiesOptionalParams,
): Promise<ListTracebilitiesResponse> => HttpClient.listTracebilities(params);

export const useConnectionsList = (params?: ListTracebilitiesOptionalParams) =>
  useQuery(['connectionsList', params], () => connectionsList(params));

// create connection
const createConnection = async (
  params?: CreateTraceabilityRequest,
): Promise<CreateTraceabilityProviderResponse> =>
  HttpClient.createTraceabilityProvider({ body: params });

export const useCreateConnection = (queryClient: QueryClient) =>
  useMutation((params: CreateTraceabilityRequest) => createConnection(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('connectionsList');
      queryClient.invalidateQueries('EndorsersList');
    },
  });

// update connection
const updateConnection = async (
  params?: UpdateTraceabilityRequest,
): Promise<UpdateTraceabilityProviderResponse> =>
  HttpClient.updateTraceabilityProvider({ body: params });

export const useUpdateConnection = (queryClient: QueryClient) =>
  useMutation((params: UpdateTraceabilityRequest) => updateConnection(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('connectionsList');
      queryClient.invalidateQueries('EndorsersList');
    },
  });

// delete connection
const deleteConnection = async (traceabilityId: string): Promise<DeleteTraceabilityResponse> =>
  HttpClient.deleteTraceability(traceabilityId);

export const useDeleteConnection = (queryClient: QueryClient) =>
  useMutation((traceabilityId: string) => deleteConnection(traceabilityId), {
    onSuccess: () => {
      queryClient.invalidateQueries('connectionsList');
      queryClient.invalidateQueries('EndorsersList');
    },
  });

// Traceability Company list
const traceabilityCompanyList = async (
  params?: ListEndorsersWithTraceabilityOptionalParams,
): Promise<ListEndorsersWithTraceabilityResponse> =>
  HttpClient.listEndorsersWithTraceability(params);

export const useTraceabilityCompanyList = (params?: ListEndorsersWithTraceabilityOptionalParams) =>
  useQuery(['traceabilityCompanyList', params], () => traceabilityCompanyList(params));

// Traceability Products list
const traceabilityProductsList = async (
  params?: ListProductsOptionalParams,
): Promise<ListProductsOperationResponse> => HttpClient.listProducts(params);

export const useTraceabilityProductsList = (params?: ListProductsOptionalParams) =>
  useQuery(['traceabilityProductsList', params], () => traceabilityProductsList(params));

const mapEvents = async (params?: MapEventsOptionalParams): Promise<MapResponse> =>
  HttpClient.mapEvents(params);

export const useEventMap = (queryClient: QueryClient, params?: MapEventsOptionalParams) =>
  useQuery(['mapEvents', params], () => mapEvents(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('searchHistory');
    },
  });

const diagramEvents = async (
  params?: DiagramEventsOptionalParams,
): Promise<DiagramEventsResponse> => HttpClient.diagramEvents(params);

export const useEventDiagram = (params?: DiagramEventsOptionalParams) =>
  useQuery(['diagramEvents', params], () => diagramEvents(params));

const listEvents = async (params?: ListEventsOptionalParams): Promise<ListEventsResponse> =>
  HttpClient.listEvents(params);

export const useEventList = (params?: ListEventsOptionalParams) =>
  useQuery(['listEvents', params], () => listEvents(params));
/* get search history query */
const searchHistory = async (
  params?: GetSearchHistoryOptionalParams,
): Promise<GetSearchHistoryResponse> => HttpClient.getSearchHistory(params);

export const useSearchHistory = (params?: GetSearchHistoryOptionalParams) =>
  useQuery(['searchHistory', params], () => searchHistory(params));
