import * as coreClient from "@azure/core-client";

export const AccessRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AccessRequest",
    modelProperties: {
      endorserId: {
        serializedName: "endorserId",
        type: {
          name: "String",
        },
      },
      auditReports: {
        serializedName: "auditReports",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const AccessResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AccessResponse",
    modelProperties: {
      endorserId: {
        serializedName: "endorserId",
        type: {
          name: "String",
        },
      },
      auditReports: {
        serializedName: "auditReports",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const GrantAccessRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GrantAccessRequest",
    modelProperties: {
      requestedEndorserId: {
        serializedName: "requestedEndorserId",
        type: {
          name: "String",
        },
      },
      accessRequests: {
        serializedName: "accessRequests",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AccessRequest",
            },
          },
        },
      },
    },
  },
};

export const GrantAccessResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GrantAccessResponse",
    modelProperties: {
      endorserId: {
        serializedName: "endorserId",
        type: {
          name: "String",
        },
      },
      accessResponses: {
        serializedName: "accessResponses",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AccessResponse",
            },
          },
        },
      },
    },
  },
};

export const ListGrantAccessRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListGrantAccessRequest",
    modelProperties: {
      requests: {
        serializedName: "requests",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "GrantAccessRequest",
            },
          },
        },
      },
    },
  },
};

export const ListGrantAccessResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListGrantAccessResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "GrantAccessResponse",
            },
          },
        },
      },
    },
  },
};

export const RevokeAccessRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RevokeAccessRequest",
    modelProperties: {
      endorserId: {
        serializedName: "endorserId",
        type: {
          name: "String",
        },
      },
      grantedEndorserId: {
        serializedName: "grantedEndorserId",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const RevokeAccessResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RevokeAccessResponse",
    modelProperties: {
      endorserId: {
        serializedName: "endorserId",
        type: {
          name: "String",
        },
      },
      accessResponses: {
        serializedName: "accessResponses",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AccessResponse",
            },
          },
        },
      },
    },
  },
};

export const UpdateAccessResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateAccessResponse",
    modelProperties: {
      endorserId: {
        serializedName: "endorserId",
        type: {
          name: "String",
        },
      },
      accepted: {
        serializedName: "accepted",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ListAccessResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListAccessResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AccessItemDTO",
            },
          },
        },
      },
    },
  },
};

export const AccessItemDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AccessItemDTO",
    modelProperties: {
      endorserId: {
        serializedName: "endorserId",
        type: {
          name: "String",
        },
      },
      endorserName: {
        serializedName: "endorserName",
        type: {
          name: "String",
        },
      },
      auditReports: {
        serializedName: "auditReports",
        type: {
          name: "Boolean",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      accepted: {
        serializedName: "accepted",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ListMyAccessResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListMyAccessResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AccessItemDTO",
            },
          },
        },
      },
    },
  },
};

export const ListAccountsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListAccountsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EndorserDTO",
            },
          },
        },
      },
    },
  },
};

export const EndorserDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EndorserDTO",
    modelProperties: {
      accountID: {
        serializedName: "accountID",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      facility: {
        serializedName: "facility",
        nullable: true,
        type: {
          name: "String",
        },
      },
      facilityType: {
        serializedName: "facilityType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      plantType: {
        serializedName: "plantType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      country: {
        serializedName: "country",
        nullable: true,
        type: {
          name: "String",
        },
      },
      expiration: {
        serializedName: "expiration",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      partnerType: {
        serializedName: "partnerType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      accessCount: {
        serializedName: "accessCount",
        type: {
          name: "Number",
        },
      },
      enhancedSocialAccountabilityDone: {
        serializedName: "enhancedSocialAccountabilityDone",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      announceType: {
        serializedName: "announceType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      access: {
        serializedName: "access",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EndorserAccess",
            },
          },
        },
      },
      species: {
        serializedName: "species",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SpeciesDTO",
            },
          },
        },
      },
    },
  },
};

export const EndorserAccess: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EndorserAccess",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String",
        },
      },
      accountID: {
        serializedName: "accountID",
        type: {
          name: "String",
        },
      },
      accessItems: {
        serializedName: "accessItems",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AccessItem",
            },
          },
        },
      },
      apiKey: {
        serializedName: "apiKey",
        nullable: true,
        type: {
          name: "String",
        },
      },
      traceabilityProvider: {
        serializedName: "traceabilityProvider",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AccessItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AccessItem",
    modelProperties: {
      endorserId: {
        serializedName: "endorserId",
        type: {
          name: "String",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        type: {
          name: "String",
        },
      },
      endorserName: {
        serializedName: "endorserName",
        type: {
          name: "String",
        },
      },
      auditReports: {
        serializedName: "auditReports",
        type: {
          name: "Boolean",
        },
      },
      accepted: {
        serializedName: "accepted",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const SpeciesDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SpeciesDTO",
    modelProperties: {
      category: {
        serializedName: "category",
        type: {
          name: "String",
        },
      },
      stars: {
        serializedName: "stars",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const ListAuditReportsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListAuditReportsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AuditReportItem",
            },
          },
        },
      },
    },
  },
};

export const AuditReportItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuditReportItem",
    modelProperties: {
      reportId: {
        serializedName: "reportId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      facilityName: {
        serializedName: "facilityName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditType: {
        serializedName: "auditType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      submittedDate: {
        serializedName: "submittedDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      auditEndDate: {
        serializedName: "auditEndDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const GetAuditReportByIdResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetAuditReportByIdResponse",
    modelProperties: {
      auditReport: {
        serializedName: "auditReport",
        type: {
          name: "Composite",
          className: "AuditReportDTO",
        },
      },
    },
  },
};

export const AuditReportDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuditReportDTO",
    modelProperties: {
      bapNumber: {
        serializedName: "bapNumber",
        type: {
          name: "String",
        },
      },
      reportId: {
        serializedName: "reportId",
        type: {
          name: "String",
        },
      },
      groupManager: {
        serializedName: "groupManager",
        nullable: true,
        type: {
          name: "String",
        },
      },
      distanceFromFarthestFacility: {
        serializedName: "distanceFromFarthestFacility",
        nullable: true,
        type: {
          name: "String",
        },
      },
      reportType: {
        serializedName: "reportType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      primaryProductionMethod: {
        serializedName: "primaryProductionMethod",
        nullable: true,
        type: {
          name: "String",
        },
      },
      cageTypes: {
        serializedName: "cageTypes",
        nullable: true,
        type: {
          name: "String",
        },
      },
      issueName: {
        serializedName: "issueName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditKpi: {
        serializedName: "auditKpi",
        type: {
          name: "Composite",
          className: "AuditKpidto",
        },
      },
      speciesDetails: {
        serializedName: "speciesDetails",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SpeciesDetailDTO",
            },
          },
        },
      },
      memberSpeciesDetails: {
        serializedName: "memberSpeciesDetails",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MemberSpeciesDetailDTO",
            },
          },
        },
      },
      speciesTotals: {
        serializedName: "speciesTotals",
        type: {
          name: "Composite",
          className: "SpeciesTotals",
        },
      },
      assessmentTotals: {
        serializedName: "assessmentTotals",
        type: {
          name: "Composite",
          className: "AssessmentTotals",
        },
      },
      groupAssessmentTotals: {
        serializedName: "groupAssessmentTotals",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AssessmentTotals",
            },
          },
        },
      },
      onSiteAssessmentTotals: {
        serializedName: "onSiteAssessmentTotals",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AssessmentTotals",
            },
          },
        },
      },
      facilityWorkforce: {
        serializedName: "facilityWorkforce",
        type: {
          name: "Composite",
          className: "AuditFacilityWorkforceDTO",
        },
      },
      auditPersonnel: {
        serializedName: "auditPersonnel",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AuditPersonnelDTO",
            },
          },
        },
      },
      assessmentAnswers: {
        serializedName: "assessmentAnswers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AssessmentAnswerDTO",
            },
          },
        },
      },
    },
  },
};

export const AuditKpidto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuditKpidto",
    modelProperties: {
      issueName: {
        serializedName: "issueName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      cbName: {
        serializedName: "cbName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      orgName: {
        serializedName: "orgName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditorName: {
        serializedName: "auditorName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditStartDate: {
        serializedName: "auditStartDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      auditEndDate: {
        serializedName: "auditEndDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      latitude: {
        serializedName: "latitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      longitude: {
        serializedName: "longitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      announceType: {
        serializedName: "announceType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      remoteDone: {
        serializedName: "remoteDone",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      catchDesignation: {
        serializedName: "catchDesignation",
        nullable: true,
        type: {
          name: "String",
        },
      },
      esaAuditDone: {
        serializedName: "esaAuditDone",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      governmentLicenseNumber: {
        serializedName: "governmentLicenseNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditContactName: {
        serializedName: "auditContactName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      website: {
        serializedName: "website",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditContactEmail: {
        serializedName: "auditContactEmail",
        nullable: true,
        type: {
          name: "String",
        },
      },
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditContactPhone: {
        serializedName: "auditContactPhone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      plantType: {
        serializedName: "plantType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      accountType: {
        serializedName: "accountType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditType: {
        serializedName: "auditType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditDurationHour: {
        serializedName: "auditDurationHour",
        nullable: true,
        type: {
          name: "String",
        },
      },
      assigned2NdAuditor: {
        serializedName: "assigned2ndAuditor",
        nullable: true,
        type: {
          name: "String",
        },
      },
      shadower: {
        serializedName: "shadower",
        nullable: true,
        type: {
          name: "String",
        },
      },
      witness: {
        serializedName: "witness",
        nullable: true,
        type: {
          name: "String",
        },
      },
      socialAccountabilityAuditor: {
        serializedName: "socialAccountabilityAuditor",
        nullable: true,
        type: {
          name: "String",
        },
      },
      observer: {
        serializedName: "observer",
        nullable: true,
        type: {
          name: "String",
        },
      },
      apscaFirmNumber: {
        serializedName: "apscaFirmNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      apscaAuditorNumber: {
        serializedName: "apscaAuditorNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditNotes: {
        serializedName: "auditNotes",
        nullable: true,
        type: {
          name: "String",
        },
      },
      siteVisitNotes: {
        serializedName: "siteVisitNotes",
        nullable: true,
        type: {
          name: "String",
        },
      },
      lastAuditDate: {
        serializedName: "lastAuditDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      submittedDate: {
        serializedName: "submittedDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      certifiedSinceDate: {
        serializedName: "certifiedSinceDate",
        nullable: true,
        type: {
          name: "String",
        },
      },
      survivalRate: {
        serializedName: "survivalRate",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      fcr: {
        serializedName: "fcr",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      companyProfile: {
        serializedName: "companyProfile",
        nullable: true,
        type: {
          name: "String",
        },
      },
      totalEmployees: {
        serializedName: "totalEmployees",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      remoteDesktopReviewTools: {
        serializedName: "remoteDesktopReviewTools",
        nullable: true,
        type: {
          name: "String",
        },
      },
      remoteDesktopReviewEffectiveness: {
        serializedName: "remoteDesktopReviewEffectiveness",
        nullable: true,
        type: {
          name: "String",
        },
      },
      remoteDocumentVerificationTools: {
        serializedName: "remoteDocumentVerificationTools",
        nullable: true,
        type: {
          name: "String",
        },
      },
      remoteDocumentVerificationEffectiveness: {
        serializedName: "remoteDocumentVerificationEffectiveness",
        nullable: true,
        type: {
          name: "String",
        },
      },
      remoteInterviewTools: {
        serializedName: "remoteInterviewTools",
        nullable: true,
        type: {
          name: "String",
        },
      },
      remoteInterviewEffectiveness: {
        serializedName: "remoteInterviewEffectiveness",
        nullable: true,
        type: {
          name: "String",
        },
      },
      remoteMeetingTools: {
        serializedName: "remoteMeetingTools",
        nullable: true,
        type: {
          name: "String",
        },
      },
      remoteMeetingEffectiveness: {
        serializedName: "remoteMeetingEffectiveness",
        nullable: true,
        type: {
          name: "String",
        },
      },
      remoteSiteInspectionTools: {
        serializedName: "remoteSiteInspectionTools",
        nullable: true,
        type: {
          name: "String",
        },
      },
      remoteSiteInspectiongEffectiveness: {
        serializedName: "remoteSiteInspectiongEffectiveness",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SpeciesDetailDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SpeciesDetailDTO",
    modelProperties: {
      scientificName: {
        serializedName: "scientificName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      speciesCategory: {
        serializedName: "speciesCategory",
        nullable: true,
        type: {
          name: "String",
        },
      },
      sourceType: {
        serializedName: "sourceType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditType: {
        serializedName: "auditType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productForms: {
        serializedName: "productForms",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productionDetails: {
        serializedName: "productionDetails",
        nullable: true,
        type: {
          name: "String",
        },
      },
      totalProduction: {
        serializedName: "totalProduction",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      latitude: {
        serializedName: "latitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      longitude: {
        serializedName: "longitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      numberOfAnimals: {
        serializedName: "numberOfAnimals",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      memberOnSiteHousing: {
        serializedName: "memberOnSiteHousing",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const MemberSpeciesDetailDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MemberSpeciesDetailDTO",
    modelProperties: {
      speciesCategory: {
        serializedName: "speciesCategory",
        nullable: true,
        type: {
          name: "String",
        },
      },
      sourceType: {
        serializedName: "sourceType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditType: {
        serializedName: "auditType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productForms: {
        serializedName: "productForms",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productionDetails: {
        serializedName: "productionDetails",
        nullable: true,
        type: {
          name: "String",
        },
      },
      latitude: {
        serializedName: "latitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      longitude: {
        serializedName: "longitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      numberOfAnimals: {
        serializedName: "numberOfAnimals",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      memberOnSiteHousing: {
        serializedName: "memberOnSiteHousing",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      speciesProduction: {
        serializedName: "speciesProduction",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SpeciesProduction",
            },
          },
        },
      },
    },
  },
};

export const SpeciesProduction: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SpeciesProduction",
    modelProperties: {
      scientificName: {
        serializedName: "scientificName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      totalProduction: {
        serializedName: "totalProduction",
        nullable: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const SpeciesTotals: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SpeciesTotals",
    modelProperties: {
      totalProduction: {
        serializedName: "totalProduction",
        type: {
          name: "Number",
        },
      },
      farmed: {
        serializedName: "farmed",
        type: {
          name: "Number",
        },
      },
      wildCaught: {
        serializedName: "wildCaught",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const AssessmentTotals: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AssessmentTotals",
    modelProperties: {
      critical: {
        serializedName: "critical",
        type: {
          name: "Number",
        },
      },
      major: {
        serializedName: "major",
        type: {
          name: "Number",
        },
      },
      minor: {
        serializedName: "minor",
        type: {
          name: "Number",
        },
      },
      yes: {
        serializedName: "yes",
        type: {
          name: "Number",
        },
      },
      notAvailable: {
        serializedName: "notAvailable",
        type: {
          name: "Number",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      phase: {
        serializedName: "phase",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AuditFacilityWorkforceDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuditFacilityWorkforceDTO",
    modelProperties: {
      totalEmployeesInFacilityHousing: {
        serializedName: "totalEmployeesInFacilityHousing",
        type: {
          name: "Number",
        },
      },
      employmentAgenciesUsed: {
        serializedName: "employmentAgenciesUsed",
        type: {
          name: "Boolean",
        },
      },
      onsiteHousing: {
        serializedName: "onsiteHousing",
        type: {
          name: "Boolean",
        },
      },
      onsiteHousingSubcontracted: {
        serializedName: "onsiteHousingSubcontracted",
        type: {
          name: "Boolean",
        },
      },
      managementNationality1: {
        serializedName: "managementNationality1",
        nullable: true,
        type: {
          name: "String",
        },
      },
      managementNationality2: {
        serializedName: "managementNationality2",
        nullable: true,
        type: {
          name: "String",
        },
      },
      managementNationality3: {
        serializedName: "managementNationality3",
        nullable: true,
        type: {
          name: "String",
        },
      },
      workersNationality1: {
        serializedName: "workersNationality1",
        nullable: true,
        type: {
          name: "String",
        },
      },
      workersNationalityPercentage1: {
        serializedName: "workersNationalityPercentage1",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      workersNationality2: {
        serializedName: "workersNationality2",
        nullable: true,
        type: {
          name: "String",
        },
      },
      workersNationalityPercentage2: {
        serializedName: "workersNationalityPercentage2",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      workersNationality3: {
        serializedName: "workersNationality3",
        nullable: true,
        type: {
          name: "String",
        },
      },
      workersNationalityPercentage3: {
        serializedName: "workersNationalityPercentage3",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      workersNationality4: {
        serializedName: "workersNationality4",
        nullable: true,
        type: {
          name: "String",
        },
      },
      workersNationalityPercentage4: {
        serializedName: "workersNationalityPercentage4",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      workersNationality5: {
        serializedName: "workersNationality5",
        nullable: true,
        type: {
          name: "String",
        },
      },
      workersNationalityPercentage5: {
        serializedName: "workersNationalityPercentage5",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      productionSupervisorMale: {
        serializedName: "productionSupervisorMale",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      productionSupervisorFemale: {
        serializedName: "productionSupervisorFemale",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      localMaleWorkersTemporary: {
        serializedName: "localMaleWorkersTemporary",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      localMaleWorkersPermanent: {
        serializedName: "localMaleWorkersPermanent",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      localMaleWorkersAgency: {
        serializedName: "localMaleWorkersAgency",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      localtFemaleWorkersTemporary: {
        serializedName: "localtFemaleWorkersTemporary",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      localFemaleWorkersPermanent: {
        serializedName: "localFemaleWorkersPermanent",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      localFemaleWorkersAgency: {
        serializedName: "localFemaleWorkersAgency",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      migrantMaleWorkersTemporary: {
        serializedName: "migrantMaleWorkersTemporary",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      migrantMaleWorkersPermanent: {
        serializedName: "migrantMaleWorkersPermanent",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      migrantMaleWorkersAgency: {
        serializedName: "migrantMaleWorkersAgency",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      migrantFemaleWorkersTemporary: {
        serializedName: "migrantFemaleWorkersTemporary",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      migrantFemaleWorkersPermanent: {
        serializedName: "migrantFemaleWorkersPermanent",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      migrantFemaleWorkersAgency: {
        serializedName: "migrantFemaleWorkersAgency",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      isSecondThirdShift: {
        serializedName: "isSecondThirdShift",
        nullable: true,
        type: {
          name: "String",
        },
      },
      otherShiftActivities: {
        serializedName: "otherShiftActivities",
        nullable: true,
        type: {
          name: "String",
        },
      },
      isOnsiteShiftProviders: {
        serializedName: "isOnsiteShiftProviders",
        nullable: true,
        type: {
          name: "String",
        },
      },
      serviceProviderDetails: {
        serializedName: "serviceProviderDetails",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AuditPersonnelDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuditPersonnelDTO",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      jobTitle: {
        serializedName: "jobTitle",
        nullable: true,
        type: {
          name: "String",
        },
      },
      openingMeeting: {
        serializedName: "openingMeeting",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      siteInspection: {
        serializedName: "siteInspection",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      procedureReview: {
        serializedName: "procedureReview",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      closingMeeting: {
        serializedName: "closingMeeting",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const AssessmentAnswerDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AssessmentAnswerDTO",
    modelProperties: {
      referenceNumber: {
        serializedName: "referenceNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      pillar: {
        serializedName: "pillar",
        nullable: true,
        type: {
          name: "String",
        },
      },
      answer: {
        serializedName: "answer",
        nullable: true,
        type: {
          name: "String",
        },
      },
      clause: {
        serializedName: "clause",
        nullable: true,
        type: {
          name: "String",
        },
      },
      explanation: {
        serializedName: "explanation",
        nullable: true,
        type: {
          name: "String",
        },
      },
      phase: {
        serializedName: "phase",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GetAssessmentAnswersResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetAssessmentAnswersResponse",
    modelProperties: {
      issueName: {
        serializedName: "issueName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      assessmentType: {
        serializedName: "assessmentType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AssessmentAnswerDTO",
            },
          },
        },
      },
    },
  },
};

export const ListFacilityLocationsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListFacilityLocationsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EndorserLocationDTO",
            },
          },
        },
      },
    },
  },
};

export const EndorserLocationDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EndorserLocationDTO",
    modelProperties: {
      accountID: {
        serializedName: "accountID",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      country: {
        serializedName: "country",
        nullable: true,
        type: {
          name: "String",
        },
      },
      addressZip: {
        serializedName: "addressZip",
        nullable: true,
        type: {
          name: "String",
        },
      },
      addressStreet2: {
        serializedName: "addressStreet2",
        nullable: true,
        type: {
          name: "String",
        },
      },
      addressStreet1: {
        serializedName: "addressStreet1",
        nullable: true,
        type: {
          name: "String",
        },
      },
      addressState: {
        serializedName: "addressState",
        nullable: true,
        type: {
          name: "String",
        },
      },
      addressCity: {
        serializedName: "addressCity",
        nullable: true,
        type: {
          name: "String",
        },
      },
      facility: {
        serializedName: "facility",
        nullable: true,
        type: {
          name: "String",
        },
      },
      latitude: {
        serializedName: "latitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      longitude: {
        serializedName: "longitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const ListEndorsersResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListEndorsersResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EndorserDTO",
            },
          },
        },
      },
    },
  },
};

export const GetEndorserByIdResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetEndorserByIdResponse",
    modelProperties: {
      endorser: {
        serializedName: "endorser",
        type: {
          name: "Composite",
          className: "EndorserDTO",
        },
      },
      traceabilityProviders: {
        serializedName: "traceabilityProviders",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TraceabilityProviderDTO",
            },
          },
        },
      },
    },
  },
};

export const TraceabilityProviderDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TraceabilityProviderDTO",
    modelProperties: {
      accountId: {
        serializedName: "accountId",
        type: {
          name: "String",
        },
      },
      traceabilityProviderId: {
        serializedName: "traceabilityProviderId",
        type: {
          name: "String",
        },
      },
      digitalLink: {
        serializedName: "digitalLink",
        type: {
          name: "String",
        },
      },
      connectionName: {
        serializedName: "connectionName",
        type: {
          name: "String",
        },
      },
      traceabilitySystem: {
        serializedName: "traceabilitySystem",
        type: {
          name: "String",
        },
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "String",
        },
      },
      gtins: {
        serializedName: "gtins",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const ListNetworkResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListNetworkResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EndorserDTO",
            },
          },
        },
      },
    },
  },
};

export const SignJwtResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SignJwtResponse",
    modelProperties: {
      signedToken: {
        serializedName: "signedToken",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListCountriesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListCountriesResponse",
    modelProperties: {
      countries: {
        serializedName: "countries",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const ListSpeciesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListSpeciesResponse",
    modelProperties: {
      species: {
        serializedName: "species",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const ListPermissionsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListPermissionsResponse",
    modelProperties: {
      permissions: {
        serializedName: "permissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PermissionItem",
            },
          },
        },
      },
    },
  },
};

export const PermissionItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PermissionItem",
    modelProperties: {
      permission: {
        serializedName: "permission",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListNotificationsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListNotificationsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationDTO",
            },
          },
        },
      },
      unreadCount: {
        serializedName: "unreadCount",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const NotificationDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NotificationDTO",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String",
        },
      },
      title: {
        serializedName: "title",
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      auditId: {
        serializedName: "auditId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime",
        },
      },
      recentActivity: {
        serializedName: "recentActivity",
        type: {
          name: "Boolean",
        },
      },
      read: {
        serializedName: "read",
        type: {
          name: "Boolean",
        },
      },
      accountId: {
        serializedName: "accountId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      pictureUrl: {
        serializedName: "pictureUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
      inviteId: {
        serializedName: "inviteId",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ClearNotificationsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ClearNotificationsRequest",
    modelProperties: {
      notificationIds: {
        serializedName: "notificationIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      recentActivity: {
        serializedName: "recentActivity",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ClearNotificationsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ClearNotificationsResponse",
    modelProperties: {
      notifications: {
        serializedName: "notifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationDTO",
            },
          },
        },
      },
    },
  },
};

export const NotifyHelpDeskRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NotifyHelpDeskRequest",
    modelProperties: {
      text: {
        serializedName: "text",
        type: {
          name: "String",
        },
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListProductsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListProductsResponse",
    modelProperties: {
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Product",
            },
          },
        },
      },
    },
  },
};

export const Product: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Product",
    modelProperties: {
      accountId: {
        serializedName: "accountId",
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String",
        },
      },
      shortDescription: {
        serializedName: "shortDescription",
        nullable: true,
        type: {
          name: "String",
        },
      },
      conditionCode: {
        serializedName: "conditionCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      speciesCode: {
        serializedName: "speciesCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      speciesName: {
        serializedName: "speciesName",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListReportsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListReportsResponse",
    modelProperties: {
      reports: {
        serializedName: "reports",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReportItemDTO",
            },
          },
        },
      },
    },
  },
};

export const ReportItemDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReportItemDTO",
    modelProperties: {
      tabName: {
        serializedName: "tabName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      url: {
        serializedName: "url",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateRoleRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateRoleRequest",
    modelProperties: {
      roleName: {
        serializedName: "roleName",
        type: {
          name: "String",
        },
      },
      permission: {
        serializedName: "permission",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const CreateUpdateRoleResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateUpdateRoleResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UpdateRoleRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateRoleRequest",
    modelProperties: {
      roleName: {
        serializedName: "roleName",
        type: {
          name: "String",
        },
      },
      permission: {
        serializedName: "permission",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListRolesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListRolesResponse",
    modelProperties: {
      roles: {
        serializedName: "roles",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleItem",
            },
          },
        },
      },
    },
  },
};

export const RoleItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RoleItem",
    modelProperties: {
      roleId: {
        serializedName: "roleId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      roleName: {
        serializedName: "roleName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      permission: {
        serializedName: "permission",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const GetRoleByIdResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetRoleByIdResponse",
    modelProperties: {
      roleId: {
        serializedName: "roleId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      roleName: {
        serializedName: "roleName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      permissions: {
        serializedName: "permissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const DeleteRoleResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DeleteRoleResponse",
    modelProperties: {
      roleId: {
        serializedName: "roleId",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AssignRolesRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AssignRolesRequest",
    modelProperties: {
      roleId: {
        serializedName: "roleId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      userId: {
        serializedName: "userId",
        type: {
          name: "String",
        },
      },
      endorserRole: {
        serializedName: "endorserRole",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AssignRolesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AssignRolesResponse",
    modelProperties: {
      roleId: {
        serializedName: "roleId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      userId: {
        serializedName: "userId",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SearchResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SearchResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SearchResult",
            },
          },
        },
      },
    },
  },
};

export const SearchResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SearchResult",
    modelProperties: {
      endorserId: {
        serializedName: "endorserId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      endorserName: {
        serializedName: "endorserName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      facilityType: {
        serializedName: "facilityType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      country: {
        serializedName: "country",
        nullable: true,
        type: {
          name: "String",
        },
      },
      species: {
        serializedName: "species",
        nullable: true,
        type: {
          name: "String",
        },
      },
      resultType: {
        serializedName: "resultType",
        type: {
          name: "String",
        },
      },
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productDescription: {
        serializedName: "productDescription",
        nullable: true,
        type: {
          name: "String",
        },
      },
      lotId: {
        serializedName: "lotId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      lotURN: {
        serializedName: "lotURN",
        nullable: true,
        type: {
          name: "String",
        },
      },
      partnerType: {
        serializedName: "partnerType",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListSearchHistoryByUserIdResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListSearchHistoryByUserIdResponse",
    modelProperties: {
      searchHistories: {
        serializedName: "searchHistories",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SearchHistoryDTO",
            },
          },
        },
      },
    },
  },
};

export const SearchHistoryDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SearchHistoryDTO",
    modelProperties: {
      userName: {
        serializedName: "userName",
        type: {
          name: "String",
        },
      },
      lotId: {
        serializedName: "lotId",
        type: {
          name: "String",
        },
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime",
        },
      },
      gtin: {
        serializedName: "gtin",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListEventsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListEventsResponse",
    modelProperties: {
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventDTO",
            },
          },
        },
      },
    },
  },
};

export const EventDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventDTO",
    modelProperties: {
      urn: {
        serializedName: "urn",
        type: {
          name: "String",
        },
      },
      eventType: {
        serializedName: "eventType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradingPartyDTO",
        },
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradingPartyDTO",
        },
      },
      bizStep: {
        serializedName: "bizStep",
        nullable: true,
        type: {
          name: "String",
        },
      },
      action: {
        serializedName: "action",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductDTO",
            },
          },
        },
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "EventLocationDTO",
        },
      },
      sourceList: {
        serializedName: "sourceList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TradingPartyDTO",
            },
          },
        },
      },
      destinationList: {
        serializedName: "destinationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TradingPartyDTO",
            },
          },
        },
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const TradingPartyDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TradingPartyDTO",
    modelProperties: {
      urn: {
        serializedName: "urn",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const EventProductDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventProductDTO",
    modelProperties: {
      epc: {
        serializedName: "epc",
        type: {
          name: "String",
        },
      },
      gtin: {
        serializedName: "gtin",
        type: {
          name: "String",
        },
      },
      shortDescription: {
        serializedName: "shortDescription",
        nullable: true,
        type: {
          name: "String",
        },
      },
      conditionCode: {
        serializedName: "conditionCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      speciesCode: {
        serializedName: "speciesCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      speciesName: {
        serializedName: "speciesName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      quantity: {
        serializedName: "quantity",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      uom: {
        serializedName: "uom",
        nullable: true,
        type: {
          name: "String",
        },
      },
      lotId: {
        serializedName: "lotId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      direction: {
        serializedName: "direction",
        nullable: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const EventLocationDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventLocationDTO",
    modelProperties: {
      urn: {
        serializedName: "urn",
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      latitude: {
        serializedName: "latitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      longitude: {
        serializedName: "longitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const MapResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MapResponse",
    modelProperties: {
      map: {
        serializedName: "map",
        type: {
          name: "Composite",
          className: "MapDiagram",
        },
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const MapDiagram: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MapDiagram",
    modelProperties: {
      nodes: {
        serializedName: "nodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationNode",
            },
          },
        },
      },
      edges: {
        serializedName: "edges",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MapDiagramEdge",
            },
          },
        },
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const LocationNode: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationNode",
    modelProperties: {
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        nullable: true,
        type: {
          name: "String",
        },
      },
      latitude: {
        serializedName: "latitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      longitude: {
        serializedName: "longitude",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventDTO",
            },
          },
        },
      },
    },
  },
};

export const MapDiagramEdge: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MapDiagramEdge",
    modelProperties: {
      source: {
        serializedName: "source",
        type: {
          name: "String",
        },
      },
      target: {
        serializedName: "target",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DiagramResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DiagramResponse",
    modelProperties: {
      diagram: {
        serializedName: "diagram",
        type: {
          name: "Composite",
          className: "EventDiagram",
        },
      },
    },
  },
};

export const EventDiagram: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventDiagram",
    modelProperties: {
      nodes: {
        serializedName: "nodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventDTO",
            },
          },
        },
      },
      edges: {
        serializedName: "edges",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MapDiagramEdge",
            },
          },
        },
      },
    },
  },
};

export const ListTraceabilityProviderResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListTraceabilityProviderResponse",
    modelProperties: {
      traceabilityProviders: {
        serializedName: "traceabilityProviders",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TraceabilityProviderDTO",
            },
          },
        },
      },
    },
  },
};

export const CreateTraceabilityRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateTraceabilityRequest",
    modelProperties: {
      connectionName: {
        serializedName: "connectionName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      digitalLink: {
        serializedName: "digitalLink",
        type: {
          name: "String",
        },
      },
      apiKey: {
        serializedName: "apiKey",
        type: {
          name: "String",
        },
      },
      gtins: {
        serializedName: "gtins",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      traceabilityProvider: {
        serializedName: "traceabilityProvider",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateTraceabilityResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateTraceabilityResponse",
    modelProperties: {
      traceabilityProviderDTO: {
        serializedName: "traceabilityProviderDTO",
        type: {
          name: "Composite",
          className: "TraceabilityProviderDTO",
        },
      },
    },
  },
};

export const UpdateTraceabilityRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateTraceabilityRequest",
    modelProperties: {
      traceabilityId: {
        serializedName: "traceabilityId",
        type: {
          name: "String",
        },
      },
      connectionName: {
        serializedName: "connectionName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      digitalLink: {
        serializedName: "digitalLink",
        nullable: true,
        type: {
          name: "String",
        },
      },
      apiKey: {
        serializedName: "apiKey",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gtins: {
        serializedName: "gtins",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const UpdateTraceabilityResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateTraceabilityResponse",
    modelProperties: {
      traceabilityId: {
        serializedName: "traceabilityId",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListEndorsersTraceabilityResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListEndorsersTraceabilityResponse",
    modelProperties: {
      endorsersAccess: {
        serializedName: "endorsersAccess",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EndorserAccessDTO",
            },
          },
        },
      },
    },
  },
};

export const EndorserAccessDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EndorserAccessDTO",
    modelProperties: {
      accountID: {
        serializedName: "accountID",
        type: {
          name: "String",
        },
      },
      traceabilityProvider: {
        serializedName: "traceabilityProvider",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bapNumber: {
        serializedName: "bapNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DeleteTraceabilityResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DeleteTraceabilityResponse",
    modelProperties: {
      accountId: {
        serializedName: "accountId",
        type: {
          name: "String",
        },
      },
      traceabilityId: {
        serializedName: "traceabilityId",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UpdateUserRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateUserRequest",
    modelProperties: {
      stateProvince: {
        serializedName: "stateProvince",
        nullable: true,
        type: {
          name: "String",
        },
      },
      city: {
        serializedName: "city",
        nullable: true,
        type: {
          name: "String",
        },
      },
      country: {
        serializedName: "country",
        nullable: true,
        type: {
          name: "String",
        },
      },
      phone: {
        serializedName: "phone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      phoneCode: {
        serializedName: "phoneCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      password: {
        serializedName: "password",
        nullable: true,
        type: {
          name: "String",
        },
      },
      displayName: {
        serializedName: "displayName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      notificationsOn: {
        serializedName: "notificationsOn",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      apiKey: {
        serializedName: "apiKey",
        nullable: true,
        type: {
          name: "String",
        },
      },
      traceabilityProvider: {
        serializedName: "traceabilityProvider",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UpdateUserResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateUserResponse",
    modelProperties: {
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserDTO",
        },
      },
    },
  },
};

export const UserDTO: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UserDTO",
    modelProperties: {
      userId: {
        serializedName: "userId",
        type: {
          name: "String",
        },
      },
      email: {
        serializedName: "email",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      phone: {
        serializedName: "phone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      phoneCode: {
        serializedName: "phoneCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      profilePictureUrl: {
        serializedName: "profilePictureUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
      role: {
        serializedName: "role",
        type: {
          name: "Composite",
          className: "RoleItem",
        },
      },
      endorserAccountId: {
        serializedName: "endorserAccountId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      firstLogin: {
        serializedName: "firstLogin",
        type: {
          name: "Boolean",
        },
      },
      userType: {
        serializedName: "userType",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const MeResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MeResponse",
    modelProperties: {
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserDTO",
        },
      },
      endorser: {
        serializedName: "endorser",
        type: {
          name: "Composite",
          className: "EndorserDTO",
        },
      },
      notificationsOn: {
        serializedName: "notificationsOn",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ListUsersResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListUsersResponse",
    modelProperties: {
      users: {
        serializedName: "users",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UserDTO",
            },
          },
        },
      },
    },
  },
};

export const GetUserByIdResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetUserByIdResponse",
    modelProperties: {
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserDTO",
        },
      },
    },
  },
};

export const DeleteUserResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DeleteUserResponse",
    modelProperties: {
      userId: {
        serializedName: "userId",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UploadProfilePictureResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UploadProfilePictureResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String",
        },
      },
      url: {
        serializedName: "url",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const InviteUserResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InviteUserResponse",
    modelProperties: {
      emails: {
        serializedName: "emails",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const PathsGp74B2UsersMePicturePostRequestbodyContentMultipartFormDataSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "PathsGp74B2UsersMePicturePostRequestbodyContentMultipartFormDataSchema",
      modelProperties: {
        picture: {
          serializedName: "picture",
          type: {
            name: "Stream",
          },
        },
      },
    },
  };

export const Paths7Ocvg4UsersInvitePostRequestbodyContentMultipartFormDataSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "Paths7Ocvg4UsersInvitePostRequestbodyContentMultipartFormDataSchema",
      modelProperties: {
        emails: {
          serializedName: "emails",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String",
              },
            },
          },
        },
        message: {
          serializedName: "message",
          type: {
            name: "String",
          },
        },
        roleId: {
          serializedName: "roleId",
          type: {
            name: "String",
          },
        },
        accountId: {
          serializedName: "accountId",
          type: {
            name: "String",
          },
        },
        endorserRole: {
          serializedName: "endorserRole",
          type: {
            name: "String",
          },
        },
      },
    },
  };

export const WebAPIClientExportReportHeaders: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WebAPIClientExportReportHeaders",
    modelProperties: {
      xFileName: {
        serializedName: "x-file-name",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const WebAPIClientGetFirstPageByIdHeaders: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WebAPIClientGetFirstPageByIdHeaders",
    modelProperties: {
      xFileName: {
        serializedName: "x-file-name",
        type: {
          name: "String",
        },
      },
    },
  },
};
