import { Space } from 'antd';
import React from 'react';
import styles from './index.module.less';
import SwitchTabButtons from './SwitchTabButtons';
import { TableTitileFilterProps } from './typings';

const TableFilterRow: React.FC<TableTitileFilterProps> = ({
  traceTab,
  setTraceTab,
  productName,
  isAllEventsListLoading,
}) => (
  <Space direction="vertical" size={16} className={styles.header}>
    <Space>
      {productName}
      <SwitchTabButtons
        traceTab={traceTab}
        setTraceTab={setTraceTab}
        isAllEventsListLoading={isAllEventsListLoading}
      />
    </Space>
    {/* <Space size={0}>
      <Tag>Event</Tag>
      <Tag>Lot: LotID</Tag>
    </Space> */}
  </Space>
);

export default TableFilterRow;
