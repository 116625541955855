import { Button, Modal, Space } from 'antd';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryBtnStyle } from 'utils';
import TraceSections from '../TraceSections';
import { SearchModalProps } from './typings';

const ModalBodyStyle = { height: '85vh', borderRadius: '0px', padding: '0px 16px' };

const SearchModal: FC<SearchModalProps> = ({
  traceSearchModal,
  setSearchValue,
  searchValue,
  traceTab,
  setTraceTab,
}) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.tables_container.modals',
  });

  const onCloseModal = useCallback(() => {
    traceSearchModal.hide();
    setSearchValue({ gtin: '', traceabilityProviderId: '', primaryId: '' });
    setTraceTab('map');
  }, [setSearchValue, setTraceTab, traceSearchModal]);

  const Footer = useMemo(
    () => (
      <Space>
        {/* <Button type="primary" ghost shape="round">
          {t('download_footer_action')}
        </Button> */}
        <Button onClick={onCloseModal} type="primary" shape="round" style={PrimaryBtnStyle}>
          {t('done_footer_action')}
        </Button>
      </Space>
    ),
    [onCloseModal, t],
  );
  return (
    <Modal
      visible={traceSearchModal.visible}
      closable
      onCancel={onCloseModal}
      width="90%"
      bodyStyle={ModalBodyStyle}
      centered
      maskClosable={false}
      footer={Footer}
    >
      <TraceSections traceTab={traceTab} setTraceTab={setTraceTab} searchValue={searchValue} />
    </Modal>
  );
};

export default SearchModal;
