/* eslint-disable no-unsafe-optional-chaining */
import { Divider, Space, Typography } from 'antd';
import { Loading } from 'components';
import GMap from 'components/GMap';
import useModalVisibility from 'hooks/useModalVisibility';
import { useCallback, useMemo, useState } from 'react';
import { LocationNode } from 'services/api/client/src';
import { dateFormat } from 'utils/helpers';
import useTraceStore from '../../hooks/useTraceStore';
import MarkerModal from './Modal';
import styles from './TraceMap.module.less';
import { MarkerItemPropsAPI, TraceMapProps } from './typings';

const MapContainerStyle = { marginTop: '10px', height: '70vh' };

const TraceMap = ({
  // map api data
  mapData,
  isLoading,
}: TraceMapProps) => {
  // Trace Map
  const selectedMarker = useTraceStore((state) => state.selectedMarker);
  const setSelectedMarker = useTraceStore((state) => state.setSelectedMarker);
  const markerModal = useModalVisibility(false);
  const [activeEvent, setActiveEvent] = useState(0);

  const mapNodes = useCallback(
    (el: LocationNode) => ({
      id: el.urn,
      position: {
        lat: Number(el.latitude) || 47.116386,
        lng: Number(el.longitude) || -101.299591,
      },
      title: el?.address,
      shortDesc: el?.address,
      longDesc: el?.address,
      label: String(el?.events?.length),
      events: el.events,
    }),
    [],
  );

  const apiMarkers: Array<MarkerItemPropsAPI> = useMemo(
    () => mapData?.nodes?.map(mapNodes) || [],
    [mapData, mapNodes],
  );

  const renderInfoWindow = (activeMarkerValue: any) => (
    <>
      <Space direction="vertical" className={styles.infowindowheader}>
        <Typography.Text strong className={styles.infotitle}>
          {activeMarkerValue?.title || ''}
        </Typography.Text>
        <Typography.Text className={styles.infosubtitle}>
          {activeMarkerValue?.shortDesc || ''}
        </Typography.Text>
        <Typography.Text className={styles.infodesc}>
          {activeMarkerValue?.longDesc || ''}
        </Typography.Text>
      </Space>
      <Divider className={styles.headerdivider} />
      <Space direction="vertical" className={styles.eventscontainer}>
        {activeMarkerValue?.events?.map((event: any, index: number) => (
          <Space
            direction="vertical"
            onClick={() => {
              setActiveEvent(index);
              markerModal.show();
            }}
            className={styles.event}
          >
            <div className={styles.eventtitle}>
              <Typography.Text className={styles.infosubtitle}>{event?.eventType}</Typography.Text>
              <Typography.Text className={styles.infodatebold}>
                {dateFormat(event?.eventTime)}
              </Typography.Text>
            </div>
            <Typography.Text ellipsis className={styles.infodesc}>{`${'Lot'}: ${
              event?.products?.[event?.products?.length - 1].lotId
            }`}</Typography.Text>
            <Typography.Text className={styles.infodesc}>{`${
              event?.products?.[event?.products?.length - 1].quantity
            } ${event?.products?.[event?.products?.length - 1].uom}`}</Typography.Text>
          </Space>
        ))}
      </Space>
    </>
  );

  return (
    <>
      {markerModal.visible && (
        <MarkerModal
          selectedMarker={selectedMarker}
          modal={markerModal}
          activeEvent={activeEvent}
        />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <GMap
          markers={apiMarkers}
          edges={mapData?.edges}
          renderInfoWindow={renderInfoWindow}
          containerStyle={MapContainerStyle}
          setSelectedMarker={setSelectedMarker}
          isRestrict
          allowFitBounds
        />
      )}
    </>
  );
};

export default TraceMap;
