import { FC } from 'react';
import { MainContainer } from './components';
import styles from './components/index.module.less';
import TopHeader from './components/TopHeader';
import { GenericDashboardProps } from './typings';

const GDashboard: FC<GenericDashboardProps> = ({
  // Top Header
  onEndorserTitleActionItemClick,
  manageAccessModal,
  locationMenuItem,
  inviteModal,
  grantAccessModal,
  ownCompany,
  backButton,
  from,
  enter,
  // Main Table Container
  tab,
  setTab,
  auditSearch,
  setAuditSearch,
  setSearch,
  // Endorser Trace
  onEndorserTraceActionItemClick,
  // Trading Partners
  onTradingPartnersActionItemClick,
  onTradingPartnersRowActionClick,
  tradePartners,
  tracePartnerSearchfilter,
  isTradePartnersLoading,
  // Network
  networkList,
  iseNetworkListLoading,
  networkListParams,
  networkTotalItems,
  onNetworkListChange,
  networkProducerSearchFilter,
  networkBapSearchFilter,
  speciesListResponse,
  countriesListResponse,
  // Access List
  myEndorsersListResponse,
  // Products
  onProductsActionItemClick,
  products,
  productSearchfilter,
  isProductsLoading,
  // Audit Reports
  onAuditReportsRowActionClick,
  auditReports,
  isAuditReportsLoading,
  totalItems,
  auditReportsParams,
  setAuditReportsParams,
  facilityNameSearchFilter,
  bapNumberSearchFilter,
  onAuditReportsTableChange,
  hasProfileAccess,
}) => (
  <>
    <TopHeader
      onEndorserTitleActionItemClick={onEndorserTitleActionItemClick}
      manageAccessModal={manageAccessModal}
      locationMenuItem={locationMenuItem}
      inviteModal={inviteModal}
      ownCompany={ownCompany}
      backButton={backButton}
      setTab={setTab}
      from={from}
      grantAccessModal={grantAccessModal}
      myEndorsersListResponse={myEndorsersListResponse}
    />
    <div className={styles.container}>
      <MainContainer
        requestAccessModal={grantAccessModal}
        manageAccessModal={manageAccessModal}
        ownCompany={ownCompany}
        // MainContainer
        tab={tab}
        setTab={setTab}
        auditSearch={auditSearch}
        setAuditSearch={setAuditSearch}
        setSearch={setSearch}
        // Endorser Trace
        onEndorserTraceActionItemClick={onEndorserTraceActionItemClick}
        // Trading Partners
        tradePartners={tradePartners}
        isTradePartnersLoading={isTradePartnersLoading}
        tracePartnerSearchfilter={tracePartnerSearchfilter}
        onTradingPartnersActionItemClick={onTradingPartnersActionItemClick}
        onTradingPartnersRowActionClick={onTradingPartnersRowActionClick}
        from={from}
        enter={enter}
        // Network
        networkList={networkList}
        iseNetworkListLoading={iseNetworkListLoading}
        networkListParams={networkListParams}
        networkTotalItems={networkTotalItems}
        onNetworkListChange={onNetworkListChange}
        networkProducerSearchFilter={networkProducerSearchFilter}
        networkBapSearchFilter={networkBapSearchFilter}
        speciesListResponse={speciesListResponse}
        countriesListResponse={countriesListResponse}
        // Access List
        myEndorsersListResponse={myEndorsersListResponse}
        // Products
        products={products}
        isProductsLoading={isProductsLoading}
        productSearchfilter={productSearchfilter}
        onProductsActionItemClick={onProductsActionItemClick}
        // Audit Reports
        auditReports={auditReports}
        isAuditReportsLoading={isAuditReportsLoading}
        auditReportsParams={auditReportsParams}
        setAuditReportsParams={setAuditReportsParams}
        totalItems={totalItems}
        hasProfileAccess={hasProfileAccess}
        facilityNameSearchFilter={facilityNameSearchFilter}
        bapNumberSearchFilter={bapNumberSearchFilter}
        onAuditReportsRowActionClick={onAuditReportsRowActionClick}
        onAuditReportsTableChange={onAuditReportsTableChange}
      />
    </div>
  </>
);

export default GDashboard;
