import { FullscreenOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { Row, Tooltip } from 'antd';
import useModalVisibility from 'hooks/useModalVisibility';
import { useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';
import useTraceStore from '../../hooks/useTraceStore';
import './diagram.css';
import styles from './index.module.less';
import NodeModal from './Modal';
import SupplyChainDiagram from './SupplychainDiagram';
import { TraceDiagramProps } from './typings';

const TraceDiagram = ({
  // event diagram data
  eventDiagramNodes,
}: TraceDiagramProps) => {
  const nodeModal = useModalVisibility(false);
  const { t } = useTranslation('pages', { keyPrefix: 'trace.trace_diagram' });
  // Trace Diagram
  const selectedNode = useTraceStore((state) => state.selectedNode);
  const setSelectedNode = useTraceStore((state) => state.setSelectedNode);
  const [fullscreenView, setFullscreenView] = useState(false);
  const [mainGraph, setMainGraph] = useState<any>(null);

  const handle = useFullScreenHandle();
  const handleFullscreenChange = (status: any) => {
    setFullscreenView(status);
  };

  const alignGraph = () => mainGraph && mainGraph.fitView();

  return (
    <>
      <Row justify="end">
        <Tooltip title={t('middle_align_icon_tooltip')} placement="bottomLeft">
          <VerticalAlignMiddleOutlined onClick={alignGraph} className={styles.pdr18} />
        </Tooltip>
        <Tooltip title={t('expand_full_screen_icon_tooltip')} placement="bottomLeft">
          <FullscreenOutlined onClick={handle.enter} className={styles.pdr18} />
        </Tooltip>
      </Row>

      {nodeModal.visible && <NodeModal modal={nodeModal} selectedNode={selectedNode} />}
      <FullScreen handle={handle} onChange={handleFullscreenChange}>
        <SupplyChainDiagram
          fullscreenView={fullscreenView}
          mainGraph={mainGraph}
          setMainGraph={setMainGraph}
          modal={nodeModal}
          setSelectedNode={setSelectedNode}
          // api event diagram
          eventDiagramNodes={eventDiagramNodes}
        />
      </FullScreen>
    </>
  );
};

export default TraceDiagram;
