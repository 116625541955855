/* eslint-disable no-nested-ternary */
import { Loading } from 'components';
import { useAuthenticationContext } from 'contexts';
import { useReportTabsContext } from 'contexts/ReportTabsProvider';
import { BlankLayout, EndorsersLayout, GeneralLayout } from 'layouts';
import { EndorsersSettingsDrawer } from 'layouts/EndorsersLayout/components/Settings';
import { GSASettingsDrawer } from 'layouts/GeneralLayout/components/Settings';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useUserInfoUpdated } from 'services/api/useSettings';
import Audits from './Audits';
import Dashboard from './Dashboard';
import Endorsers from './Endorsers';
import ViewCompany, { StaffAudit } from './Endorsers/components';
import TradingPartnersDashboard, {
  Audit as StaffTradingPartnersAudit,
} from './Endorsers/components/TradingPartnersDashboard';
import Login from './Login';
import Reports from './Reports';

// Endorsers Workflow
import { Discover, ReportTab } from './EndorsersFlow';
import ProfileDashboard, { Audit } from './EndorsersFlow/Discover/Profile';
import ProfileTradingPartnersDashboard, {
  Audit as ProfileTradingPartnersAudit,
} from './EndorsersFlow/Discover/Profile/TradingPartnersDashboard';
import MainDashboard, { EndorsersAudit } from './EndorsersFlow/MainDashboard';
import EndorsersTradingPartnersDashboard, {
  Audit as TradingPartnersAudit,
} from './EndorsersFlow/MainDashboard/TradingPartnersDashboard';

import RedeemInvite from './RedeemInvite';

const Pages = () => {
  const { isAuthenticated } = useAuthenticationContext();
  const { data: loggedInUserData, isLoading } = useUserInfoUpdated({
    inProgress: isAuthenticated,
  });

  const { endorsersReportsData } = useReportTabsContext();

  if (!loggedInUserData && isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<BlankLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/redeem_invite" element={<RedeemInvite />} />
        </Route>
        {loggedInUserData?.user?.endorserAccountId === null ? (
          loggedInUserData?.user?.firstLogin ? (
            <Route index element={<RedeemInvite />} />
          ) : (
            <Route element={<GeneralLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="endorsers_partners/*">
                <Route index element={<Endorsers />} />
                <Route path=":accountID/*">
                  <Route index element={<ViewCompany />} />
                  <Route path=":accountID/*">
                    <Route index element={<TradingPartnersDashboard />} />
                    <Route
                      path="audit_report/:bapNumber/:reportId"
                      element={<StaffTradingPartnersAudit />}
                    />
                  </Route>
                  <Route path="audit_report/:bapNumber/:reportId" element={<StaffAudit />} />
                </Route>
              </Route>
              <Route path="audits/*">
                <Route index element={<Audits />} />
              </Route>
              <Route path="reports/*">
                <Route index element={<Reports />} />
              </Route>
              <Route path="settings" element={<GSASettingsDrawer />} />
            </Route>
          )
        ) : loggedInUserData?.user?.firstLogin ? (
          <Route index element={<RedeemInvite />} />
        ) : (
          <Route element={<EndorsersLayout />}>
            <Route index element={<MainDashboard />} />
            <Route path=":bapNumber/*">
              <Route index element={<EndorsersTradingPartnersDashboard />} />
              <Route path="audit_report/:bapNumber/:reportId" element={<TradingPartnersAudit />} />
            </Route>
            <Route path="audit_report/:bapNumber/:reportId" element={<EndorsersAudit />} />
            <Route path="discover/*">
              <Route index element={<Discover />} />
              <Route path=":accountID/*">
                <Route index element={<ProfileDashboard />} />
                <Route path=":bapNumber/*">
                  <Route index element={<ProfileTradingPartnersDashboard />} />
                  <Route
                    path="audit_report/:bapNumber/:reportId"
                    element={<ProfileTradingPartnersAudit />}
                  />
                </Route>
                <Route path="audit_report/:bapNumber/:reportId" element={<Audit />} />
              </Route>
            </Route>
            {endorsersReportsData?.reports?.map((tab) => (
              <Route path={`${tab?.tabName?.toLowerCase()}/*`}>
                <Route index element={<ReportTab />} />
              </Route>
            ))}
            <Route path="settings" element={<EndorsersSettingsDrawer />} />
          </Route>
        )}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
