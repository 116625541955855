import useAuditReportStore from 'components/GAuditReport/hooks';
import GDashboard from 'components/GDashboard';
import useModalVisibility from 'hooks/useModalVisibility';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuditReportsProps, ProductList, TradePartnersList } from 'services/api/Endorsers';
import { useGetMyAllAccessList } from 'services/api/Endorsers/useAccess';
import { useUserInfo } from 'services/api/useSettings';
import useNetworkListFilters, { useAuditReportsFilters, useTabStore } from './hooks';
import useTraceStore from './hooks/useTraceStore';

const MainDashboard = () => {
  const { data: loggedInUserData } = useUserInfo();
  const bap = useMemo(() => loggedInUserData?.endorser?.bapNumber, [loggedInUserData]);

  const navigate = useNavigate();
  // invite modal
  const inviteModal = useModalVisibility(false);
  const grantAccessModal = useModalVisibility(false);

  const tab = useTabStore((state) => state.tab);
  const setTab = useTabStore((state) => state.setTab);
  const search = useTabStore((state) => state.auditSearch);
  const setAuditSearch = useTabStore((state) => state.setAuditSearch);
  const setSearch = useTraceStore((state) => state.setSearch);

  const setTraceTab = useTraceStore((state) => state.setTraceTab);

  // set audit tab
  const toggleAuditReportShow = useTabStore((state) => state.toggleAuditReportShow);

  useEffect(
    () => () => {
      setAuditSearch({ facilityName: '', date: undefined, bapNumber: bap, status: '' });
      setSearch({
        company: [],
        lot: [],
        location: [],
        state: [],
        country: [],
        sscc: [],
        event: [],
        productIdentifier: [],
        species: [],
        startDate: undefined,
        endDate: undefined,
      });
      setTraceTab('list');
      toggleAuditReportShow(false);
      // if (backFrom === 'auditReport') {
      //   setTab('auditReports');
      //   setBackFrom('tradingPartners');
      // } else {
      //   setTab('tradingPartners');
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const locationMenuItem = () => [
    {
      label: 'All Locations',
      key: '0',
    },
    {
      label: 'USA',
      key: '1',
    },
    {
      label: 'India',
      key: '2',
    },
  ];

  const onEndorserTitleActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'invite':
        inviteModal.show();
        break;
      case 'grantAccess':
        grantAccessModal.show();
        break;

      default:
        break;
    }
  };

  // Endorser Trace
  const onEndorserTraceActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'viewProduct':
        break;

      default:
        break;
    }
  };

  // Trading partners
  const [params, setParams] = useState({
    page: '1',
    itemsPerPage: 20,
    search: '',
  });

  const tradePartners = [] as Array<TradePartnersList>;

  const onSearch = (text: string) => {
    setParams({
      ...params,
      page: '1',
      search: text,
    });
  };

  const { filters: tracePartnerSearchfilter } = useTableSearchFilter({
    onSearch,
  });

  const onTradingPartnersActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'view':
        break;

      default:
        break;
    }
  };

  const onTradingPartnersRowActionClick = (
    actionItemKey: string,
    actionPayload?: TradePartnersList,
  ) => {
    switch (actionItemKey) {
      case 'viewDashboard':
        if (actionPayload) {
          navigate(actionPayload.companyName, {
            state: { facilityName: actionPayload?.companyName },
          });
        }
        break;

      default:
        break;
    }
  };

  // Products
  const [productParams, setProductParams] = useState({
    page: '1',
    itemsPerPage: 20,
    search: '',
  });

  const products = [] as Array<ProductList>;

  const onProductSearch = (text: string) => {
    setProductParams({
      ...productParams,
      page: '1',
      search: text,
    });
  };

  const { filters: productSearchfilter } = useTableSearchFilter({
    onSearch: onProductSearch,
  });

  const onProductsActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'viewProduct':
        break;

      default:
        break;
    }
  };

  // all Audit Reports
  const {
    auditReports,
    filters: [facilityNameSearchFilter, bapNumberSearchFilter],
    auditReportsParams,
    setAuditReportsParams,
    totalItems,
    isAuditReportsLoading,
    onAuditReportsTableChange,
  } = useAuditReportsFilters({ bapNumber: bap });

  const setModalVisible = useAuditReportStore((state) => state.setModalVisible);
  const setAuditReportId = useAuditReportStore((state) => state.setAuditReportId);
  const setAuditReportBAP = useAuditReportStore((state) => state.setAuditReportBAP);
  const setCheckedBox = useAuditReportStore((state) => state.setCheckedBox);

  const onAuditReportsRowActionClick = (
    actionItemKey: string,
    actionPayload?: AuditReportsProps,
  ) => {
    switch (actionItemKey) {
      case 'view':
        navigate(`audit_report/${actionPayload?.bapNumber}/${actionPayload?.reportId}`);
        break;
      case 'download':
        setCheckedBox(['summary', 'auditReport']);
        setAuditReportId(actionPayload?.reportId || '');
        setAuditReportBAP(actionPayload?.bapNumber || '');
        setTimeout(() => {
          setModalVisible(true);
        }, 450);
        break;
      default:
        break;
    }
  };

  // network List
  const {
    networkList,
    iseNetworkListLoading,
    networkListParams,
    networkTotalItems,
    onNetworkListChange,
    filters: [networkProducerSearchFilter, networkBapSearchFilter],
    speciesListResponse,
    countriesListResponse,
  } = useNetworkListFilters();

  // Access List
  const { data: myEndorsersListResponse } = useGetMyAllAccessList(
    {},
    !!loggedInUserData?.user?.endorserAccountId,
  );
  return (
    <GDashboard
      onEndorserTitleActionItemClick={onEndorserTitleActionItemClick}
      locationMenuItem={locationMenuItem()}
      inviteModal={inviteModal}
      grantAccessModal={grantAccessModal}
      ownCompany
      from="endorsersMain"
      enter={false}
      // MainTableCOntainer
      tab={tab}
      setTab={setTab}
      auditSearch={search}
      setAuditSearch={setAuditSearch}
      setSearch={setSearch}
      // Endorser Trace
      onEndorserTraceActionItemClick={onEndorserTraceActionItemClick}
      // Trading Partners
      onTradingPartnersActionItemClick={onTradingPartnersActionItemClick}
      onTradingPartnersRowActionClick={onTradingPartnersRowActionClick}
      tradePartners={tradePartners}
      tracePartnerSearchfilter={tracePartnerSearchfilter}
      isTradePartnersLoading={false}
      // Network
      networkList={networkList}
      iseNetworkListLoading={iseNetworkListLoading}
      networkListParams={networkListParams}
      networkTotalItems={networkTotalItems}
      onNetworkListChange={onNetworkListChange}
      networkProducerSearchFilter={networkProducerSearchFilter}
      networkBapSearchFilter={networkBapSearchFilter}
      speciesListResponse={speciesListResponse}
      countriesListResponse={countriesListResponse}
      // access List
      myEndorsersListResponse={myEndorsersListResponse}
      // Products
      onProductsActionItemClick={onProductsActionItemClick}
      products={products}
      productSearchfilter={productSearchfilter}
      isProductsLoading={false}
      // Audit Reports
      onAuditReportsRowActionClick={onAuditReportsRowActionClick}
      auditReports={auditReports}
      isAuditReportsLoading={isAuditReportsLoading}
      totalItems={totalItems}
      auditReportsParams={auditReportsParams}
      setAuditReportsParams={setAuditReportsParams}
      facilityNameSearchFilter={facilityNameSearchFilter}
      bapNumberSearchFilter={bapNumberSearchFilter}
      onAuditReportsTableChange={onAuditReportsTableChange}
    />
  );
};

export default MainDashboard;
