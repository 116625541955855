import { Form } from 'antd';
import { useState } from 'react';
import { CreateTraceabilityRequest, UpdateTraceabilityRequest } from 'services/api/client/src';
import { TraceabilityColumns } from '../components/TraceabilityTable/TraceabilityTable';
import { ConnectionModal } from './typings';

const useConnectionModal = (): ConnectionModal => {
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [traceabilityProvider, setTraceabilityProvider] = useState<TraceabilityColumns | null>(
    null,
  );
  const [visible, setVisible] = useState<boolean>(false);
  const [step, setStep] = useState<'connection' | 'gtins'>('connection');
  const [form] = Form.useForm<CreateTraceabilityRequest | UpdateTraceabilityRequest>();

  const show = (traceabilityProviderParam?: TraceabilityColumns) => {
    if (traceabilityProviderParam) {
      setMode('edit');
      setTraceabilityProvider(traceabilityProviderParam);
    }
    setVisible(true);
  };

  return {
    traceabilityProvider,
    form,
    show,
    visible,
    setVisible,
    mode,
    setMode,
    step,
    setStep,
  };
};

export default useConnectionModal;
