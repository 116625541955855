import { SearchOutlined } from '@ant-design/icons';
import { ProPageHeader } from '@ant-design/pro-layout';
import { ProColumns } from '@ant-design/pro-table';
import { Button, Col, Form, Input, message, Row, Select, Typography } from 'antd';
import { GTable } from 'components';
import useModalVisibility from 'hooks/useModalVisibility';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { TraceabilityProviderDTO } from 'services/api/client/src';
import { SearchModal } from './components';
import useTraceFilters from './hooks';
import useTraceStore from './hooks/useTraceStore';
import styles from './index.module.less';
import { GTraceProps, TraceSearchParams, TraceTitleProps } from './typings';

export interface TraceSearchFormData {
  company?: string;
  product?: string;
  lotId?: string;
}

export interface SearchHistoryProps {
  lot?: string;
  product?: string;
  user?: string;
  date?: string;
}

type ColumnsType = {
  onTraceTableRowActionClick: (actionPayload?: SearchHistoryProps) => void;
  t: TFunction<'pages', 'endorsers_partners.view_company.tables_container'>;
  location: {
    pathname: string;
  };
};

const columns = ({ t }: ColumnsType): Array<ProColumns<SearchHistoryProps>> => [
  {
    title: t('primary_id'),
    dataIndex: 'lot',
    ellipsis: true,
    fixed: 'left',
  },
  {
    title: t('gtin'),
    dataIndex: 'gtin',
    ellipsis: true,
  },
  {
    title: t('user'),
    dataIndex: 'userName',
    ellipsis: true,
  },
  {
    title: t('date_of_search'),
    dataIndex: 'searchDate',
    ellipsis: true,
    valueType: 'date',
    fieldProps: {
      format: 'YYYY-MM-DD hh:mm',
    },
  },
];

const TraceTitle = ({
  traceSearchModal,
  searchValue,
  setSearchValue,
  t,
  traceabilityProviders,
}: TraceTitleProps) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [traceabilityProvider, setTraceabilityProvider] = useState<TraceabilityProviderDTO | null>(
    null,
  );

  const onFinishFailed = () => {
    if (location.pathname.includes('/trace')) {
      if (searchValue.traceabilityProviderId === '' && searchValue.primaryId === '') {
        message.error(t('error_messages.select_company_and_lot'));
        return;
      }
      if (searchValue.gtin === '' && searchValue.primaryId === '') {
        message.error(t('error_messages.select_product_and_lot'));
        return;
      }
      if (searchValue.primaryId.trim() === '') {
        message.error(t('error_messages.input_primary_id'));
        return;
      }
      if (searchValue.primaryId.trim() !== '' && searchValue.traceabilityProviderId === '') {
        message.error(t('error_messages.select_company'));
        return;
      }
      if (searchValue.primaryId.trim() !== '' && searchValue.gtin === '') {
        message.error(t('error_messages.select_product'));
      }
    } else {
      if (searchValue.gtin === '' && searchValue.primaryId === '') {
        message.error(t('error_messages.select_product_and_lot'));
        return;
      }
      if (searchValue.primaryId.trim() !== '' && searchValue.gtin === '') {
        message.error(t('error_messages.select_product'));
        return;
      }
      if (searchValue.primaryId.trim() === '') {
        message.error(t('error_messages.input_primary_id'));
      }
    }
  };

  const onFinish = () => {
    traceSearchModal.show();
    form.resetFields();
  };

  return (
    <>
      <ProPageHeader
        prefixedClassName="titlebar"
        title={t('trace_title')}
        className={styles['page-header']}
      />
      <div className={styles.headertext}>{t('enter_lot_id_text')}</div>
      <div className={styles.searchfields}>
        <Form<TraceSearchFormData>
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <Row gutter={0}>
            <Col span={4}>
              <Form.Item
                label={t('form_msg.traceability')}
                name="company"
                rules={[{ required: true, message: t('form_msg.select_traceability') }]}
              >
                <Select
                  onChange={(_, record: any) => {
                    form.setFieldsValue({
                      traceabilityProviderId: record?.value,
                    });
                    setSearchValue({ ...searchValue, traceabilityProviderId: record?.value || '' });
                    setTraceabilityProvider(
                      traceabilityProviders.filter(
                        (provider) => provider.traceabilityProviderId === record?.value,
                      )[0],
                    );
                  }}
                  options={traceabilityProviders.map((provider) => ({
                    value: provider.traceabilityProviderId,
                    label: provider.connectionName,
                  }))}
                  placeholder={t('form_msg.traceability_placeholder')}
                  style={{ width: 'calc(100% - 5px)' }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('form_msg.gtin')}
                name="gtin"
                rules={[
                  {
                    required: !(searchValue.traceabilityProviderId === ''),
                    message: t('form_msg.select_gtin'),
                  },
                ]}
              >
                <Select
                  onChange={(event: any, record: any) => {
                    form.setFieldsValue({
                      product: record?.label,
                    });
                    setSearchValue({ ...searchValue, gtin: record?.label || '' });
                  }}
                  options={traceabilityProvider?.gtins?.map((gtin) => ({
                    value: gtin,
                    label: gtin,
                  }))}
                  disabled={
                    location.pathname.includes('/trace') &&
                    searchValue?.traceabilityProviderId === ''
                  }
                  placeholder={t('form_msg.product_placeholder')}
                  style={{ width: 'calc(100% - 5px)' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('form_msg.primary_id')}
                name="lotId"
                rules={[{ required: true, message: t('form_msg.input_primary_id') }]}
              >
                <div>
                  <Input
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      form.setFieldsValue({
                        lotId: event.target.value,
                      });
                      setSearchValue({ ...searchValue, primaryId: event.target.value || '' });
                    }}
                    placeholder={t('search_placeholder')}
                    style={{ width: 'calc(100% - 32px)' }}
                  />
                  <Button type="primary" htmlType="submit" icon={<SearchOutlined />} />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Typography.Title level={5}>{t('search_history_header')}</Typography.Title>
    </>
  );
};

const GTrace = ({ traceabilityProviders }: GTraceProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'endorsers_partners.view_company.tables_container',
  });
  const location = useLocation();
  // Trace search history table
  const { searchHistory, isSearchHistoryLoading } = useTraceFilters();
  const traceSearchModal = useModalVisibility(false);

  // Trace section search params
  const [searchValue, setSearchValue] = useState<TraceSearchParams>({
    gtin: '',
    traceabilityProviderId: '',
    primaryId: '',
  });

  // Switch Button
  const traceTab = useTraceStore((state) => state.traceTab);
  const setTraceTab = useTraceStore((state) => state.setTraceTab);

  const onTraceTableRowActionClick = () => {
    traceSearchModal.show();
  };

  useEffect(
    () => () => {
      setTraceTab('map');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      {traceSearchModal && traceSearchModal.visible && (
        <SearchModal
          traceSearchModal={traceSearchModal}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          traceTab={traceTab}
          setTraceTab={setTraceTab}
        />
      )}
      <GTable<SearchHistoryProps>
        columns={columns({ onTraceTableRowActionClick, t, location })}
        headerTitle={
          <TraceTitle
            traceSearchModal={traceSearchModal}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            t={t}
            traceabilityProviders={traceabilityProviders}
          />
        }
        loading={isSearchHistoryLoading}
        value={searchHistory}
        options={{
          setting: false,
          reload: false,
        }}
        recordCreatorProps={false}
      />
    </>
  );
};

export default React.memo(GTrace);
