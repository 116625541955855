import * as coreClient from "@azure/core-client";
import * as coreRestPipeline from "@azure/core-rest-pipeline";
import * as coreAuth from "@azure/core-auth";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  WebAPIClientOptionalParams,
  RequestEndorserAccessOptionalParams,
  RequestEndorserAccessResponse,
  GrantEndorserAccessOptionalParams,
  GrantEndorserAccessResponse,
  ListGrantEndorserAccessOptionalParams,
  ListGrantEndorserAccessResponse,
  RevokeAccessOptionalParams,
  RevokeAccessOperationResponse,
  AcceptInviteOptionalParams,
  AcceptInviteResponse,
  RejectInviteOptionalParams,
  RejectInviteResponse,
  ListEndorserAccessItemsOptionalParams,
  ListEndorserAccessItemsResponse,
  ListMyEndorserAccessItemsOptionalParams,
  ListMyEndorserAccessItemsResponse,
  ListAccountsOptionalParams,
  ListAccountsOperationResponse,
  ListAuditReportsOptionalParams,
  ListAuditReportsOperationResponse,
  GetAuditReportByIdOptionalParams,
  GetAuditReportByIdOperationResponse,
  GetAssessmentAnswersOptionalParams,
  GetAssessmentAnswersOperationResponse,
  ExportReportOptionalParams,
  ExportReportResponse,
  GetFirstPageByIdOptionalParams,
  GetFirstPageByIdResponse,
  ListFacilityLocationsOptionalParams,
  ListFacilityLocationsOperationResponse,
  ListEndorsersOptionalParams,
  ListEndorsersOperationResponse,
  GetEndorserByIdOptionalParams,
  GetEndorserByIdOperationResponse,
  ListNetworkOptionalParams,
  ListNetworkOperationResponse,
  SignJwtOptionalParams,
  SignJwtOperationResponse,
  ListCountriesOptionalParams,
  ListCountriesOperationResponse,
  ListSpeciesOptionalParams,
  ListSpeciesOperationResponse,
  ListPermissionsOptionalParams,
  ListPermissionsOperationResponse,
  ListNotificationsOptionalParams,
  ListNotificationsOperationResponse,
  ClearNotificationsOptionalParams,
  ClearNotificationsOperationResponse,
  NotifyHelpDeskOptionalParams,
  NotifyHelpDeskResponse,
  ListProductsOptionalParams,
  ListProductsOperationResponse,
  ListReportsOptionalParams,
  ListReportsOperationResponse,
  CreateRoleOptionalParams,
  CreateRoleResponse,
  UpdateRoleOptionalParams,
  UpdateRoleResponse,
  ListRolesOptionalParams,
  ListRolesOperationResponse,
  GetRoleByIdOptionalParams,
  GetRoleByIdOperationResponse,
  DeleteRoleOptionalParams,
  DeleteRoleOperationResponse,
  AssignRolesOptionalParams,
  AssignRolesOperationResponse,
  SearchOptionalParams,
  SearchOperationResponse,
  GetSearchHistoryOptionalParams,
  GetSearchHistoryResponse,
  ListEventsOptionalParams,
  ListEventsOperationResponse,
  MapEventsOptionalParams,
  MapEventsResponse,
  DiagramEventsOptionalParams,
  DiagramEventsResponse,
  ListTracebilitiesOptionalParams,
  ListTracebilitiesResponse,
  CreateTraceabilityProviderOptionalParams,
  CreateTraceabilityProviderResponse,
  UpdateTraceabilityProviderOptionalParams,
  UpdateTraceabilityProviderResponse,
  ListEndorsersWithTraceabilityOptionalParams,
  ListEndorsersWithTraceabilityResponse,
  DeleteTraceabilityOptionalParams,
  DeleteTraceabilityOperationResponse,
  UpdateUserOptionalParams,
  UpdateUserOperationResponse,
  MeOptionalParams,
  MeOperationResponse,
  ListUsersOptionalParams,
  ListUsersOperationResponse,
  GetUserByIdOptionalParams,
  GetUserByIdOperationResponse,
  DeleteUserOptionalParams,
  DeleteUserOperationResponse,
  UploadProfilePictureOptionalParams,
  UploadProfilePictureOperationResponse,
  InviteOptionalParams,
  InviteResponse,
} from "./models";

export class WebAPIClient extends coreClient.ServiceClient {
  $host: string;

  /**
   * Initializes a new instance of the WebAPIClient class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param $host server parameter
   * @param options The parameter options
   */
  constructor(
    credentials: coreAuth.TokenCredential,
    $host: string,
    options?: WebAPIClientOptionalParams,
  ) {
    if (credentials === undefined) {
      throw new Error("'credentials' cannot be null");
    }
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: WebAPIClientOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
      credential: credentials,
    };

    const packageDetails = `azsdk-js-WebAPIClient/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    if (!options.credentialScopes) {
      options.credentialScopes = [
        "https://gsab2cdev.onmicrosoft.com/558d6af1-6e88-4b83-9cf6-5ec4143c3503/.default",
      ];
    }
    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint: options.endpoint ?? options.baseUri ?? "{$host}",
    };
    super(optionsWithDefaults);

    let bearerTokenAuthenticationPolicyFound: boolean = false;
    if (options?.pipeline && options.pipeline.getOrderedPolicies().length > 0) {
      const pipelinePolicies: coreRestPipeline.PipelinePolicy[] =
        options.pipeline.getOrderedPolicies();
      bearerTokenAuthenticationPolicyFound = pipelinePolicies.some(
        (pipelinePolicy) =>
          pipelinePolicy.name ===
          coreRestPipeline.bearerTokenAuthenticationPolicyName,
      );
    }
    if (
      !options ||
      !options.pipeline ||
      options.pipeline.getOrderedPolicies().length == 0 ||
      !bearerTokenAuthenticationPolicyFound
    ) {
      this.pipeline.removePolicy({
        name: coreRestPipeline.bearerTokenAuthenticationPolicyName,
      });
      this.pipeline.addPolicy(
        coreRestPipeline.bearerTokenAuthenticationPolicy({
          credential: credentials,
          scopes:
            optionsWithDefaults.credentialScopes ??
            `${optionsWithDefaults.endpoint}/.default`,
          challengeCallbacks: {
            authorizeRequestOnChallenge:
              coreClient.authorizeRequestOnClaimChallenge,
          },
        }),
      );
    }
    // Parameter assignments
    this.$host = $host;
  }

  /** @param options The options parameters. */
  requestEndorserAccess(
    options?: RequestEndorserAccessOptionalParams,
  ): Promise<RequestEndorserAccessResponse> {
    return this.sendOperationRequest(
      { options },
      requestEndorserAccessOperationSpec,
    );
  }

  /** @param options The options parameters. */
  grantEndorserAccess(
    options?: GrantEndorserAccessOptionalParams,
  ): Promise<GrantEndorserAccessResponse> {
    return this.sendOperationRequest(
      { options },
      grantEndorserAccessOperationSpec,
    );
  }

  /** @param options The options parameters. */
  listGrantEndorserAccess(
    options?: ListGrantEndorserAccessOptionalParams,
  ): Promise<ListGrantEndorserAccessResponse> {
    return this.sendOperationRequest(
      { options },
      listGrantEndorserAccessOperationSpec,
    );
  }

  /** @param options The options parameters. */
  revokeAccess(
    options?: RevokeAccessOptionalParams,
  ): Promise<RevokeAccessOperationResponse> {
    return this.sendOperationRequest({ options }, revokeAccessOperationSpec);
  }

  /**
   * @param endorserId
   * @param options The options parameters.
   */
  acceptInvite(
    endorserId: string,
    options?: AcceptInviteOptionalParams,
  ): Promise<AcceptInviteResponse> {
    return this.sendOperationRequest(
      { endorserId, options },
      acceptInviteOperationSpec,
    );
  }

  /**
   * @param endorserId
   * @param options The options parameters.
   */
  rejectInvite(
    endorserId: string,
    options?: RejectInviteOptionalParams,
  ): Promise<RejectInviteResponse> {
    return this.sendOperationRequest(
      { endorserId, options },
      rejectInviteOperationSpec,
    );
  }

  /**
   * @param endorserId
   * @param options The options parameters.
   */
  listEndorserAccessItems(
    endorserId: string,
    options?: ListEndorserAccessItemsOptionalParams,
  ): Promise<ListEndorserAccessItemsResponse> {
    return this.sendOperationRequest(
      { endorserId, options },
      listEndorserAccessItemsOperationSpec,
    );
  }

  /** @param options The options parameters. */
  listMyEndorserAccessItems(
    options?: ListMyEndorserAccessItemsOptionalParams,
  ): Promise<ListMyEndorserAccessItemsResponse> {
    return this.sendOperationRequest(
      { options },
      listMyEndorserAccessItemsOperationSpec,
    );
  }

  /** @param options The options parameters. */
  listAccounts(
    options?: ListAccountsOptionalParams,
  ): Promise<ListAccountsOperationResponse> {
    return this.sendOperationRequest({ options }, listAccountsOperationSpec);
  }

  /** @param options The options parameters. */
  listAuditReports(
    options?: ListAuditReportsOptionalParams,
  ): Promise<ListAuditReportsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listAuditReportsOperationSpec,
    );
  }

  /**
   * @param bapNumber
   * @param reportId
   * @param options The options parameters.
   */
  getAuditReportById(
    bapNumber: string,
    reportId: string,
    options?: GetAuditReportByIdOptionalParams,
  ): Promise<GetAuditReportByIdOperationResponse> {
    return this.sendOperationRequest(
      { bapNumber, reportId, options },
      getAuditReportByIdOperationSpec,
    );
  }

  /**
   * @param reportId
   * @param bapNumber
   * @param options The options parameters.
   */
  getAssessmentAnswers(
    reportId: string,
    bapNumber: string,
    options?: GetAssessmentAnswersOptionalParams,
  ): Promise<GetAssessmentAnswersOperationResponse> {
    return this.sendOperationRequest(
      { reportId, bapNumber, options },
      getAssessmentAnswersOperationSpec,
    );
  }

  /**
   * @param reportId
   * @param bapNumber
   * @param options The options parameters.
   */
  exportReport(
    reportId: string,
    bapNumber: string,
    options?: ExportReportOptionalParams,
  ): Promise<ExportReportResponse> {
    return this.sendOperationRequest(
      { reportId, bapNumber, options },
      exportReportOperationSpec,
    );
  }

  /**
   * @param bapNumber
   * @param reportId
   * @param options The options parameters.
   */
  getFirstPageById(
    bapNumber: string,
    reportId: string,
    options?: GetFirstPageByIdOptionalParams,
  ): Promise<GetFirstPageByIdResponse> {
    return this.sendOperationRequest(
      { bapNumber, reportId, options },
      getFirstPageByIdOperationSpec,
    );
  }

  /** @param options The options parameters. */
  listFacilityLocations(
    options?: ListFacilityLocationsOptionalParams,
  ): Promise<ListFacilityLocationsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listFacilityLocationsOperationSpec,
    );
  }

  /** @param options The options parameters. */
  listEndorsers(
    options?: ListEndorsersOptionalParams,
  ): Promise<ListEndorsersOperationResponse> {
    return this.sendOperationRequest({ options }, listEndorsersOperationSpec);
  }

  /**
   * @param accountId
   * @param options The options parameters.
   */
  getEndorserById(
    accountId: string,
    options?: GetEndorserByIdOptionalParams,
  ): Promise<GetEndorserByIdOperationResponse> {
    return this.sendOperationRequest(
      { accountId, options },
      getEndorserByIdOperationSpec,
    );
  }

  /** @param options The options parameters. */
  listNetwork(
    options?: ListNetworkOptionalParams,
  ): Promise<ListNetworkOperationResponse> {
    return this.sendOperationRequest({ options }, listNetworkOperationSpec);
  }

  /** @param options The options parameters. */
  signJwt(options?: SignJwtOptionalParams): Promise<SignJwtOperationResponse> {
    return this.sendOperationRequest({ options }, signJwtOperationSpec);
  }

  /** @param options The options parameters. */
  listCountries(
    options?: ListCountriesOptionalParams,
  ): Promise<ListCountriesOperationResponse> {
    return this.sendOperationRequest({ options }, listCountriesOperationSpec);
  }

  /** @param options The options parameters. */
  listSpecies(
    options?: ListSpeciesOptionalParams,
  ): Promise<ListSpeciesOperationResponse> {
    return this.sendOperationRequest({ options }, listSpeciesOperationSpec);
  }

  /** @param options The options parameters. */
  listPermissions(
    options?: ListPermissionsOptionalParams,
  ): Promise<ListPermissionsOperationResponse> {
    return this.sendOperationRequest({ options }, listPermissionsOperationSpec);
  }

  /** @param options The options parameters. */
  listNotifications(
    options?: ListNotificationsOptionalParams,
  ): Promise<ListNotificationsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listNotificationsOperationSpec,
    );
  }

  /** @param options The options parameters. */
  clearNotifications(
    options?: ClearNotificationsOptionalParams,
  ): Promise<ClearNotificationsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      clearNotificationsOperationSpec,
    );
  }

  /** @param options The options parameters. */
  notifyHelpDesk(
    options?: NotifyHelpDeskOptionalParams,
  ): Promise<NotifyHelpDeskResponse> {
    return this.sendOperationRequest({ options }, notifyHelpDeskOperationSpec);
  }

  /** @param options The options parameters. */
  listProducts(
    options?: ListProductsOptionalParams,
  ): Promise<ListProductsOperationResponse> {
    return this.sendOperationRequest({ options }, listProductsOperationSpec);
  }

  /** @param options The options parameters. */
  listReports(
    options?: ListReportsOptionalParams,
  ): Promise<ListReportsOperationResponse> {
    return this.sendOperationRequest({ options }, listReportsOperationSpec);
  }

  /** @param options The options parameters. */
  createRole(options?: CreateRoleOptionalParams): Promise<CreateRoleResponse> {
    return this.sendOperationRequest({ options }, createRoleOperationSpec);
  }

  /** @param options The options parameters. */
  updateRole(options?: UpdateRoleOptionalParams): Promise<UpdateRoleResponse> {
    return this.sendOperationRequest({ options }, updateRoleOperationSpec);
  }

  /** @param options The options parameters. */
  listRoles(
    options?: ListRolesOptionalParams,
  ): Promise<ListRolesOperationResponse> {
    return this.sendOperationRequest({ options }, listRolesOperationSpec);
  }

  /**
   * @param roleId
   * @param options The options parameters.
   */
  getRoleById(
    roleId: string,
    options?: GetRoleByIdOptionalParams,
  ): Promise<GetRoleByIdOperationResponse> {
    return this.sendOperationRequest(
      { roleId, options },
      getRoleByIdOperationSpec,
    );
  }

  /**
   * @param roleId
   * @param options The options parameters.
   */
  deleteRole(
    roleId: string,
    options?: DeleteRoleOptionalParams,
  ): Promise<DeleteRoleOperationResponse> {
    return this.sendOperationRequest(
      { roleId, options },
      deleteRoleOperationSpec,
    );
  }

  /** @param options The options parameters. */
  assignRoles(
    options?: AssignRolesOptionalParams,
  ): Promise<AssignRolesOperationResponse> {
    return this.sendOperationRequest({ options }, assignRolesOperationSpec);
  }

  /** @param options The options parameters. */
  search(options?: SearchOptionalParams): Promise<SearchOperationResponse> {
    return this.sendOperationRequest({ options }, searchOperationSpec);
  }

  /** @param options The options parameters. */
  getSearchHistory(
    options?: GetSearchHistoryOptionalParams,
  ): Promise<GetSearchHistoryResponse> {
    return this.sendOperationRequest(
      { options },
      getSearchHistoryOperationSpec,
    );
  }

  /** @param options The options parameters. */
  listEvents(
    options?: ListEventsOptionalParams,
  ): Promise<ListEventsOperationResponse> {
    return this.sendOperationRequest({ options }, listEventsOperationSpec);
  }

  /** @param options The options parameters. */
  mapEvents(options?: MapEventsOptionalParams): Promise<MapEventsResponse> {
    return this.sendOperationRequest({ options }, mapEventsOperationSpec);
  }

  /** @param options The options parameters. */
  diagramEvents(
    options?: DiagramEventsOptionalParams,
  ): Promise<DiagramEventsResponse> {
    return this.sendOperationRequest({ options }, diagramEventsOperationSpec);
  }

  /** @param options The options parameters. */
  listTracebilities(
    options?: ListTracebilitiesOptionalParams,
  ): Promise<ListTracebilitiesResponse> {
    return this.sendOperationRequest(
      { options },
      listTracebilitiesOperationSpec,
    );
  }

  /** @param options The options parameters. */
  createTraceabilityProvider(
    options?: CreateTraceabilityProviderOptionalParams,
  ): Promise<CreateTraceabilityProviderResponse> {
    return this.sendOperationRequest(
      { options },
      createTraceabilityProviderOperationSpec,
    );
  }

  /** @param options The options parameters. */
  updateTraceabilityProvider(
    options?: UpdateTraceabilityProviderOptionalParams,
  ): Promise<UpdateTraceabilityProviderResponse> {
    return this.sendOperationRequest(
      { options },
      updateTraceabilityProviderOperationSpec,
    );
  }

  /** @param options The options parameters. */
  listEndorsersWithTraceability(
    options?: ListEndorsersWithTraceabilityOptionalParams,
  ): Promise<ListEndorsersWithTraceabilityResponse> {
    return this.sendOperationRequest(
      { options },
      listEndorsersWithTraceabilityOperationSpec,
    );
  }

  /**
   * @param traceabilityId
   * @param options The options parameters.
   */
  deleteTraceability(
    traceabilityId: string,
    options?: DeleteTraceabilityOptionalParams,
  ): Promise<DeleteTraceabilityOperationResponse> {
    return this.sendOperationRequest(
      { traceabilityId, options },
      deleteTraceabilityOperationSpec,
    );
  }

  /** @param options The options parameters. */
  updateUser(
    options?: UpdateUserOptionalParams,
  ): Promise<UpdateUserOperationResponse> {
    return this.sendOperationRequest({ options }, updateUserOperationSpec);
  }

  /** @param options The options parameters. */
  me(options?: MeOptionalParams): Promise<MeOperationResponse> {
    return this.sendOperationRequest({ options }, meOperationSpec);
  }

  /** @param options The options parameters. */
  listUsers(
    options?: ListUsersOptionalParams,
  ): Promise<ListUsersOperationResponse> {
    return this.sendOperationRequest({ options }, listUsersOperationSpec);
  }

  /**
   * @param userId
   * @param options The options parameters.
   */
  getUserById(
    userId: string,
    options?: GetUserByIdOptionalParams,
  ): Promise<GetUserByIdOperationResponse> {
    return this.sendOperationRequest(
      { userId, options },
      getUserByIdOperationSpec,
    );
  }

  /**
   * @param userId
   * @param options The options parameters.
   */
  deleteUser(
    userId: string,
    options?: DeleteUserOptionalParams,
  ): Promise<DeleteUserOperationResponse> {
    return this.sendOperationRequest(
      { userId, options },
      deleteUserOperationSpec,
    );
  }

  /** @param options The options parameters. */
  uploadProfilePicture(
    options?: UploadProfilePictureOptionalParams,
  ): Promise<UploadProfilePictureOperationResponse> {
    return this.sendOperationRequest(
      { options },
      uploadProfilePictureOperationSpec,
    );
  }

  /** @param options The options parameters. */
  invite(options?: InviteOptionalParams): Promise<InviteResponse> {
    return this.sendOperationRequest({ options }, inviteOperationSpec);
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const requestEndorserAccessOperationSpec: coreClient.OperationSpec = {
  path: "/Access/Request",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.AccessResponse,
    },
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const grantEndorserAccessOperationSpec: coreClient.OperationSpec = {
  path: "/Access/Grant",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.GrantAccessResponse,
    },
  },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const listGrantEndorserAccessOperationSpec: coreClient.OperationSpec = {
  path: "/Access/ListGrant",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ListGrantAccessResponse,
    },
  },
  requestBody: Parameters.body2,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const revokeAccessOperationSpec: coreClient.OperationSpec = {
  path: "/Access/Revoke",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.RevokeAccessResponse,
    },
  },
  requestBody: Parameters.body3,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const acceptInviteOperationSpec: coreClient.OperationSpec = {
  path: "/Access/{endorserId}/Accept",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateAccessResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.endorserId],
  headerParameters: [Parameters.accept],
  serializer,
};
const rejectInviteOperationSpec: coreClient.OperationSpec = {
  path: "/Access/{endorserId}/Reject",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateAccessResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.endorserId],
  headerParameters: [Parameters.accept],
  serializer,
};
const listEndorserAccessItemsOperationSpec: coreClient.OperationSpec = {
  path: "/Access/{endorserId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListAccessResponse,
    },
  },
  queryParameters: [Parameters.endorserName, Parameters.bapNumber],
  urlParameters: [Parameters.$host, Parameters.endorserId],
  headerParameters: [Parameters.accept],
  serializer,
};
const listMyEndorserAccessItemsOperationSpec: coreClient.OperationSpec = {
  path: "/Access",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListMyAccessResponse,
    },
  },
  queryParameters: [Parameters.endorserName, Parameters.bapNumber],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listAccountsOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListAccountsResponse,
    },
  },
  queryParameters: [
    Parameters.bapNumber1,
    Parameters.facility,
    Parameters.childAccessFacility,
    Parameters.facilityTypes,
    Parameters.countries,
    Parameters.expiration,
    Parameters.species,
    Parameters.order,
    Parameters.direction,
    Parameters.hideSelf,
    Parameters.excludeIds,
    Parameters.pageNumber,
    Parameters.pageSize,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listAuditReportsOperationSpec: coreClient.OperationSpec = {
  path: "/AuditReports",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListAuditReportsResponse,
    },
  },
  queryParameters: [
    Parameters.bapNumber1,
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.facilityName,
    Parameters.status,
    Parameters.submittedDate,
    Parameters.orderBy,
    Parameters.direction1,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getAuditReportByIdOperationSpec: coreClient.OperationSpec = {
  path: "/AuditReports/{bapNumber}/{reportId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetAuditReportByIdResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.bapNumber2, Parameters.reportId],
  headerParameters: [Parameters.accept],
  serializer,
};
const getAssessmentAnswersOperationSpec: coreClient.OperationSpec = {
  path: "/AuditReports/{bapNumber}/{reportId}/AssessmentAnswers",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetAssessmentAnswersResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.referenceNumber,
    Parameters.pillars,
    Parameters.bAPNumbers,
    Parameters.phases,
    Parameters.answers,
    Parameters.causeExplanation,
    Parameters.order1,
    Parameters.direction2,
  ],
  urlParameters: [Parameters.$host, Parameters.bapNumber2, Parameters.reportId],
  headerParameters: [Parameters.accept],
  serializer,
};
const exportReportOperationSpec: coreClient.OperationSpec = {
  path: "/AuditReports/{bapNumber}/{reportId}/Export",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Stream" },
        serializedName: "parsedResponse",
      },
      headersMapper: Mappers.WebAPIClientExportReportHeaders,
    },
  },
  queryParameters: [
    Parameters.referenceNumber,
    Parameters.pillars,
    Parameters.answers,
    Parameters.causeExplanation,
    Parameters.order2,
    Parameters.direction3,
    Parameters.summary,
    Parameters.fullReport,
  ],
  urlParameters: [Parameters.$host, Parameters.bapNumber2, Parameters.reportId],
  headerParameters: [Parameters.accept1],
  serializer,
};
const getFirstPageByIdOperationSpec: coreClient.OperationSpec = {
  path: "/AuditReports/{bapNumber}/{reportId}/FirstPage",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Stream" },
        serializedName: "parsedResponse",
      },
      headersMapper: Mappers.WebAPIClientGetFirstPageByIdHeaders,
    },
  },
  urlParameters: [Parameters.$host, Parameters.bapNumber2, Parameters.reportId],
  headerParameters: [Parameters.accept1],
  serializer,
};
const listFacilityLocationsOperationSpec: coreClient.OperationSpec = {
  path: "/Dashboard/Locations",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListFacilityLocationsResponse,
    },
  },
  queryParameters: [Parameters.pageNumber, Parameters.pageSize],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listEndorsersOperationSpec: coreClient.OperationSpec = {
  path: "/Endorsers",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListEndorsersResponse,
    },
  },
  queryParameters: [
    Parameters.bapNumber1,
    Parameters.facility,
    Parameters.childAccessFacility,
    Parameters.facilityTypes,
    Parameters.countries,
    Parameters.expiration,
    Parameters.species,
    Parameters.hideSelf,
    Parameters.excludeIds,
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.partnerTypes,
    Parameters.eSA,
    Parameters.announceType,
    Parameters.order3,
    Parameters.direction4,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getEndorserByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Endorsers/{accountId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetEndorserByIdResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.accountId],
  headerParameters: [Parameters.accept],
  serializer,
};
const listNetworkOperationSpec: coreClient.OperationSpec = {
  path: "/Endorsers/Network",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListNetworkResponse,
    },
  },
  queryParameters: [
    Parameters.bapNumber1,
    Parameters.facility,
    Parameters.facilityTypes,
    Parameters.countries,
    Parameters.expiration,
    Parameters.species,
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.partnerTypes,
    Parameters.eSA,
    Parameters.announceType,
    Parameters.order4,
    Parameters.direction5,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const signJwtOperationSpec: coreClient.OperationSpec = {
  path: "/Metabase/Sign",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.SignJwtResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listCountriesOperationSpec: coreClient.OperationSpec = {
  path: "/Misc/Countries",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListCountriesResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listSpeciesOperationSpec: coreClient.OperationSpec = {
  path: "/Misc/Species",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListSpeciesResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listPermissionsOperationSpec: coreClient.OperationSpec = {
  path: "/Misc/Permissions",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListPermissionsResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listNotificationsOperationSpec: coreClient.OperationSpec = {
  path: "/Notifications",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListNotificationsResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.recentActivity,
    Parameters.fromParam,
    Parameters.to,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const clearNotificationsOperationSpec: coreClient.OperationSpec = {
  path: "/Notifications/Clear",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.ClearNotificationsResponse,
    },
  },
  requestBody: Parameters.body4,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const notifyHelpDeskOperationSpec: coreClient.OperationSpec = {
  path: "/Notifications/Help",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } },
      },
    },
  },
  requestBody: Parameters.body5,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const listProductsOperationSpec: coreClient.OperationSpec = {
  path: "/Products",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListProductsResponse,
    },
  },
  queryParameters: [Parameters.digitalLink],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listReportsOperationSpec: coreClient.OperationSpec = {
  path: "/Reports",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListReportsResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createRoleOperationSpec: coreClient.OperationSpec = {
  path: "/Roles",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateUpdateRoleResponse,
    },
  },
  requestBody: Parameters.body6,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const updateRoleOperationSpec: coreClient.OperationSpec = {
  path: "/Roles",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.CreateUpdateRoleResponse,
    },
  },
  requestBody: Parameters.body7,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const listRolesOperationSpec: coreClient.OperationSpec = {
  path: "/Roles",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListRolesResponse,
    },
  },
  queryParameters: [Parameters.roleName, Parameters.permissions],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getRoleByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Roles/{roleId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetRoleByIdResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.roleId],
  headerParameters: [Parameters.accept],
  serializer,
};
const deleteRoleOperationSpec: coreClient.OperationSpec = {
  path: "/Roles/{roleId}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.DeleteRoleResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.roleId],
  headerParameters: [Parameters.accept],
  serializer,
};
const assignRolesOperationSpec: coreClient.OperationSpec = {
  path: "/Roles/Assign",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.AssignRolesResponse,
    },
  },
  requestBody: Parameters.body8,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const searchOperationSpec: coreClient.OperationSpec = {
  path: "/Search",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.SearchResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.term,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getSearchHistoryOperationSpec: coreClient.OperationSpec = {
  path: "/SearchHistory",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListSearchHistoryByUserIdResponse,
    },
  },
  queryParameters: [Parameters.pageNumber, Parameters.pageSize],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listEventsOperationSpec: coreClient.OperationSpec = {
  path: "/Traceability/events",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListEventsResponse,
    },
  },
  queryParameters: [
    Parameters.traceabilityId,
    Parameters.gtin,
    Parameters.primaryId,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const mapEventsOperationSpec: coreClient.OperationSpec = {
  path: "/Traceability/map",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.MapResponse,
    },
  },
  queryParameters: [
    Parameters.traceabilityId,
    Parameters.gtin,
    Parameters.primaryId,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const diagramEventsOperationSpec: coreClient.OperationSpec = {
  path: "/Traceability/diagram",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.DiagramResponse,
    },
  },
  queryParameters: [
    Parameters.traceabilityId,
    Parameters.gtin,
    Parameters.primaryId,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listTracebilitiesOperationSpec: coreClient.OperationSpec = {
  path: "/TraceabilityProvider",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListTraceabilityProviderResponse,
    },
  },
  queryParameters: [Parameters.request],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createTraceabilityProviderOperationSpec: coreClient.OperationSpec = {
  path: "/TraceabilityProvider",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateTraceabilityResponse,
    },
  },
  requestBody: Parameters.body9,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const updateTraceabilityProviderOperationSpec: coreClient.OperationSpec = {
  path: "/TraceabilityProvider",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateTraceabilityResponse,
    },
  },
  requestBody: Parameters.body10,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const listEndorsersWithTraceabilityOperationSpec: coreClient.OperationSpec = {
  path: "/TraceabilityProvider/ListEndorsersWithTraceability",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListEndorsersTraceabilityResponse,
    },
  },
  queryParameters: [Parameters.pageNumber, Parameters.pageSize],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const deleteTraceabilityOperationSpec: coreClient.OperationSpec = {
  path: "/TraceabilityProvider/{traceabilityId}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.DeleteTraceabilityResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.traceabilityId1],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateUserOperationSpec: coreClient.OperationSpec = {
  path: "/Users/Me",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateUserResponse,
    },
  },
  requestBody: Parameters.body11,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const meOperationSpec: coreClient.OperationSpec = {
  path: "/Users/Me",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.MeResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listUsersOperationSpec: coreClient.OperationSpec = {
  path: "/Users",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListUsersResponse,
    },
  },
  queryParameters: [Parameters.email, Parameters.name, Parameters.roleName1],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getUserByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Users/{userId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetUserByIdResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.userId],
  headerParameters: [Parameters.accept],
  serializer,
};
const deleteUserOperationSpec: coreClient.OperationSpec = {
  path: "/Users/{userId}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.DeleteUserResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.userId],
  headerParameters: [Parameters.accept],
  serializer,
};
const uploadProfilePictureOperationSpec: coreClient.OperationSpec = {
  path: "/Users/Me/Picture",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.UploadProfilePictureResponse,
    },
  },
  formDataParameters: [Parameters.picture],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType1, Parameters.accept2],
  serializer,
};
const inviteOperationSpec: coreClient.OperationSpec = {
  path: "/Users/Invite",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.InviteUserResponse,
    },
  },
  formDataParameters: [
    Parameters.emails,
    Parameters.message,
    Parameters.roleId1,
    Parameters.accountId1,
    Parameters.endorserRole,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType1, Parameters.accept2],
  serializer,
};
