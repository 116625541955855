import { Button, Col, Modal, Row, Space, Typography } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryBtnStyle } from 'utils';
import EventDetailsContent from '../../EventDetailsContent';
import styles from './index.module.less';
import { MarkerModalProps } from './typings';

const { Title } = Typography;

const ModalBodyStyle = { height: '60%' };

const MarkerModal = ({ selectedMarker, modal, activeEvent }: MarkerModalProps) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_diagram',
  });

  const onCloseModal = useCallback(() => {
    modal.hide();
  }, [modal]);

  const renderSection = useCallback(
    () => (
      <EventDetailsContent
        event={selectedMarker?.events?.[activeEvent]}
        lot={selectedMarker?.events?.[activeEvent].products?.[0]}
      />
    ),
    [selectedMarker, activeEvent],
  );

  const Footer = useMemo(
    () => (
      <Space>
        <Button onClick={onCloseModal} type="primary" shape="round" style={PrimaryBtnStyle}>
          {t('modal.done')}
        </Button>
      </Space>
    ),
    [onCloseModal, t],
  );

  const ModalTitle = useMemo(
    () => (
      <Row gutter={0}>
        <Col className={styles.modaltitle}>
          <Title level={4}>
            {selectedMarker?.events?.[activeEvent].products?.[0].shortDescription}
          </Title>
        </Col>
      </Row>
    ),
    [activeEvent, selectedMarker?.events],
  );

  return (
    <Modal
      visible={modal.visible}
      title={ModalTitle}
      closable
      onCancel={onCloseModal}
      footer={Footer}
      width="58%"
      bodyStyle={ModalBodyStyle}
      centered
    >
      {renderSection()}
    </Modal>
  );
};

export default MarkerModal;
