import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';
import { SwitchButtonProps } from './typings';

const SwitchTabButtons: React.FC<SwitchButtonProps> = ({ traceTab, setTraceTab }) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'trace.trace_header',
  });

  const handleModeChange = useCallback(
    (e: RadioChangeEvent) => {
      setTraceTab(e.target.value);
    },
    [setTraceTab],
  );

  return (
    <Radio.Group onChange={handleModeChange} value={traceTab}>
      <Radio.Button value="map" className={styles.radiobtn}>
        {t('map_button_text')}
      </Radio.Button>
      <Radio.Button value="list" className={styles.radiobtn}>
        {t('list_button_text')}
      </Radio.Button>
      <Radio.Button value="diagram" className={styles.radiobtn}>
        {t('diagram_button_text')}
      </Radio.Button>
    </Radio.Group>
  );
};

export default React.memo(SwitchTabButtons);
