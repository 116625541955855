import { UploadOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Button } from 'antd';
import { GTable } from 'components';
import EndorserActions from 'components/Actions/EndorserActions';
import { useCallback, useRef } from 'react';
import { PrimaryBtnStyle, SecondaryBtnStyle } from 'utils';
import { GTINColumn, GTINTableProps } from './typings';

import styles from './index.module.less';

const GTINTable = ({
  gtins,
  setGtins,
  addLabel,
  uploadLabel,
  editLabel,
  deleteLabel,
}: GTINTableProps) => {
  const gtinTableRef = useRef<GTINColumn & ActionType>();
  const actionItems = [
    {
      key: 'edit',
      label: editLabel,
    },
    {
      key: 'delete',
      label: deleteLabel,
    },
  ];

  const onSaveRow = async (_: any, data: GTINColumn) => {
    setGtins(
      data.index < gtins.length
        ? gtins.map((item) => (item.id === data.id ? data : item))
        : [...gtins, data],
    );
  };

  const addGtin = useCallback(() => {
    gtinTableRef.current?.addEditRecord?.({
      id: `newField${Date.now().toString() + Math.random().toString()}`,
    });
  }, [gtinTableRef]);

  const onEditRow = (actionPayload?: GTINColumn) => {
    gtinTableRef.current?.startEditable(actionPayload?.id || 0);
  };

  const onDeleteRow = (actionPayload?: GTINColumn) => {
    setGtins(gtins.filter((item) => item.gtin !== actionPayload?.gtin));
  };

  const onRowActionClick = async (actionItemKey: string, actionPayload?: GTINColumn) => {
    switch (actionItemKey) {
      case 'edit':
        onEditRow(actionPayload);
        break;
      case 'delete':
        onDeleteRow(actionPayload);
        break;

      default:
        break;
    }
  };

  const columns: Array<ProColumns<GTINColumn>> = [
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      ellipsis: true,
      width: '90%',
    },
    {
      title: '',
      dataIndex: 'actions',
      valueType: 'option',
      hideInSetting: true,
      align: 'center',
      width: '10%',
      render: (text, record) => (
        <EndorserActions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          size="small"
          items={actionItems}
          onClick={onRowActionClick}
          actionButtonColor={PrimaryBtnStyle}
          actionPayload={record}
          buttonClass={styles.gtinActions}
        />
      ),
    },
  ];

  return (
    <>
      <Button style={PrimaryBtnStyle} shape="round" onClick={addGtin}>
        {addLabel}
      </Button>
      <Button style={{ ...SecondaryBtnStyle, border: '0px' }} shape="round">
        <UploadOutlined />
        {uploadLabel}
      </Button>
      <GTable<GTINColumn>
        key="TraceabilityTable"
        actionRef={gtinTableRef}
        columns={columns}
        editable={{
          onSave: (rowKey, data) => onSaveRow(rowKey, data),
          onCancel: async (_rowKey, data) => {
            gtinTableRef.current?.cancelEditable(data?.index || 0);
          },
        }}
        value={gtins}
        options={{
          setting: false,
          reload: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
      />
    </>
  );
};

export default GTINTable;
