import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { PrimaryBtnStyle } from 'utils';
import { ActionsProps } from './typings';

const EndorserActions = <ActionPayloadType extends {}>({
  actionPayload,
  className,
  items,
  onClick,
  trigger,
  actionButtonColor = PrimaryBtnStyle,
  ghost = false,
  actionsText = 'Actions',
  size = 'middle',
}: ActionsProps<ActionPayloadType>) => {
  if (items.length <= 0) {
    return null;
  }

  const onItemClick: MenuClickEventHandler = ({ key }) => {
    onClick(key, actionPayload);
  };

  return (
    <Dropdown
      className={className}
      overlay={<Menu onClick={onItemClick} items={items} />}
      overlayStyle={{ borderRadius: '2px', zIndex: '1001' }}
      placement="bottomRight"
      trigger={trigger}
    >
      <Button type="primary" shape="round" size={size} style={actionButtonColor} ghost={ghost}>
        {actionsText}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default EndorserActions;
