import { useCallback, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { useConnectionsList } from 'services/api/useTraceability';

const useConnectionListFilters = () => {
  const [connectionListParams, setConnectionListParams] = useState({});
  const { data: connectionsList, isLoading } = useConnectionsList();

  const onConnectionListTableChange = useCallback((pagination: TablePaginationConfig) => {
    const paginate = pagination?.current
      ? {
          pageNumber: pagination?.current || 1,
          pageSize: pagination?.pageSize || 50,
        }
      : {};

    setConnectionListParams({
      ...paginate,
    });
  }, []);

  return {
    connectionListParams,
    setConnectionListParams,
    connectionsList,
    isLoading,
    onConnectionListTableChange,
  };
};

export default useConnectionListFilters;
